import _isEqual from "lodash/isEqual";
import React, { memo, ReactElement } from "react";

import { ProfileType } from "auth/actions";
import Avatar from "components/Avatar";

import GlobeIcon from "assets/icons/globe";

type SidebarProps = {
  profile: ProfileType;
};

function ProfileSummary({ profile }: SidebarProps): ReactElement {
  // TODO return an empty state if no username - profile is private.

  return (
    <>
      <Avatar size="large" profile={profile} />
      <h2 className="font-sans text-base truncate">{`@${profile?.username}`}</h2>
      {profile?.bio && (
        <p className="text-sm whitespace-pre-line">{profile.bio}</p>
      )}
      {profile?.website && (
        <ul>
          <li className="space-x-1 truncate whitespace-nowrap">
            <GlobeIcon className="inline w-4 h-4" />
            <a
              href={profile?.website}
              className="text-sm truncate"
              target="_blank"
              rel="noreferrer"
            >
              {profile?.website}
            </a>
          </li>
        </ul>
      )}
    </>
  );
}

const MemoisedSidebar = memo(ProfileSummary, (prevProps, nextProps) =>
  _isEqual(prevProps.profile, nextProps.profile)
);

export default MemoisedSidebar;
