import React, { ReactElement } from "react";

import PopularTags from "layouts/components/PopularTags";
import { BookmarksSuccessCallback } from "bookmarks/actions";
import { usePublicBookmarks } from "bookmarks/hooks";
import BookmarkGrid, { InfiniteBookmarksResult } from "components/BookmarkGrid";
import SearchForm from "components/SearchForm";
import { useApp } from "services/AppContext";

function Home(): ReactElement {
  const { updateBookmarkStore, searchResults = {} as InfiniteBookmarksResult } =
    useApp();

  const bookmarks = usePublicBookmarks(
    updateBookmarkStore as BookmarksSuccessCallback
  );

  return (
    <>
      <PopularTags />
      <SearchForm />
      <div className="p-4 sm:px-6 lg:px-16">
        {bookmarks && searchResults && (
          <BookmarkGrid
            bookmarks={
              !searchResults.error && searchResults.data
                ? (searchResults as InfiniteBookmarksResult)
                : (bookmarks as InfiniteBookmarksResult)
            }
            emptyStyle="sparkle"
            className="md:grid-cols-3 lg:grid-cols-4"
          />
        )}
      </div>
    </>
  );
}

export default Home;
