import { Auth } from "@supabase/ui";
import { useHistory } from "react-router-dom";
import { ReactElement } from "react";

import PlusIcon from "assets/icons/plus";

function Fab(): ReactElement {
  const { session } = Auth.useUser();
  const history = useHistory();

  const handleClick = () => {
    history.push("/bookmarks/add");
  };

  if (!session) {
    return <></>;
  }

  return (
    <div className="fixed right-4 bottom-4">
      <button
        aria-label="add-bookmark"
        className="p-3 text-white bg-ink rounded-full"
        onClick={handleClick}
      >
        <PlusIcon className="w-6 h-6" />
      </button>
    </div>
  );
}

export default Fab;
