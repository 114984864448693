import _capitalize from "lodash/capitalize";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { UseQueryResult } from "react-query/types/react/types";

import { TagType } from "tags/actions";
import { usePopularTags } from "tags/hooks";

function PopularTags(): ReactElement {
  const popularTags: UseQueryResult<TagType[]> = usePopularTags();

  return !popularTags.error ? (
    <nav
      role="navigation"
      aria-label="popular-tags"
      className="flex overflow-hidden flex-wrap justify-between py-2 px-4 sm:px-6 lg:px-16 space-x-2 h-14 border-t"
    >
      {popularTags.data &&
        popularTags?.data?.map((tag: TagType) => (
          <Link key={tag.slug} className="p-2" to={`/tags/${tag.slug}`}>
            {_capitalize(tag.name)}
          </Link>
        ))}
    </nav>
  ) : (
    <></>
  );
}

export default PopularTags;
