import { Auth } from "@supabase/ui";
import clsx from "clsx";
import _isUndefined from "lodash/isUndefined";
import queryString from "query-string";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router-dom";

import supabase from "services/supabase";

import Logo from "assets/svg/logo.svg";

const { REACT_APP_SITE_URL } = process.env;

function Login(): ReactElement {
  const { session } = Auth.useUser();
  const { pathname, search, state = {} } = useLocation<{ from?: string }>();
  const { from } = state;
  const { t } = useTranslation();
  const qs = queryString.parse(search);
  const isExt = !_isUndefined(qs.ext);

  if (session) {
    return <Redirect to={from ?? "/"} />;
  }

  let redirectTo = from ?? "/";
  redirectTo = `${REACT_APP_SITE_URL}${redirectTo}`;

  return (
    <div
      className={clsx("p-3 m-auto max-w-md card", !isExt ? "my-10" : undefined)}
    >
      {isExt && (
        <img
          src={Logo}
          alt={t("Thesaurus Inventus Logo")}
          className="my-4 mx-auto w-8 md:w-10 h-8 md:h-10"
        />
      )}
      <Auth
        supabaseClient={supabase}
        providers={["google", "facebook"]}
        socialColors
        redirectTo={redirectTo}
        view={pathname === "/register" ? "sign_up" : "sign_in"}
      />
    </div>
  );
}

export default Login;
