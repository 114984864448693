import clsx from "clsx";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import CakeIcon from "assets/icons/cake";
import SparkleIcon from "assets/icons/sparkle";

function EmptyState({
  className,
  type = "cake",
}: {
  className?: string;
  type?: "cake" | "sparkle";
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={clsx("my-10 w-full text-center", className)}>
      {type === "cake" ? (
        <>
          <CakeIcon className="mx-auto w-20 h-20" />
          <p className="mt-4 text-xl">{t("Hello! We're shiny and new!")}</p>
        </>
      ) : (
        <>
          <SparkleIcon className="mx-auto w-20 h-20" strokeWidth={1} />
          <p className="mt-4 text-xl">{t("Nothing here.")}</p>
        </>
      )}
    </div>
  );
}

export default EmptyState;
