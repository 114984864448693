import { User } from "@supabase/supabase-js";
import {
  MutationFunction,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import { UseQueryResult } from "react-query/types/react/types";

import authActions, { profileKeys, ProfileType } from "auth/actions";
import supabase from "services/supabase";

export function useChangeEmail(): UseMutationResult {
  return useMutation(authActions.changeEmail as MutationFunction<User>);
}

export function useChangePassword(): UseMutationResult {
  return useMutation(authActions.changePassword as MutationFunction<User>);
}

export function useOwnProfile(): UseQueryResult<ProfileType> {
  return useQuery(
    profileKeys.detail(supabase?.auth?.user()?.id as string),
    authActions.getOwnProfile
  );
}

export function useUserProfile(id?: string): UseQueryResult<ProfileType> {
  return useQuery(
    profileKeys.detail(id as string),
    () => authActions.getUserProfile(id),
    {
      enabled: !!id,
    }
  );
}

export function useUserProfileByUsername(
  username?: string
): UseQueryResult<ProfileType> {
  return useQuery(
    `user_profile-${username}`,
    () => authActions.getUserProfileByUsername(username),
    {
      enabled: !!username,
    }
  );
}

export function useUpdateProfile(): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(
    authActions.updateProfile as MutationFunction<ProfileType>,
    {
      onSuccess: (profile: ProfileType) => {
        queryClient.invalidateQueries([
          profileKeys.detail(supabase?.auth?.user()?.id as string),
          profile.username ? `profile-${profile.username}` : undefined,
        ]);
      },
    }
  );
}
