import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "@supabase/ui";
import clsx from "clsx";
import _pick from "lodash/pick";
import { ReactElement, useEffect } from "react";
import Gravatar from "react-gravatar";
import { useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { ProfileType } from "auth/actions";
import { useOwnProfile, useUpdateProfile } from "auth/hooks";
import ChangeEmailForm from "auth/components/ChangeEmailForm";
import ChangePasswordForm from "auth/components/ChangePasswordForm";
import ChevronRightIcon from "assets/icons/chevron-right";
import LibraryIcon from "assets/icons/library";
import Error500 from "components/Error/500";
import Spinner from "components/Spinner";
import { ServerError } from "utils/RequestError";
import { formatServerError } from "utils/helpers";

type FormValues = {
  display_name: string;
  username: string;
  bio: string;
  website: string;
};

const ProfileSchema = Yup.object().shape({
  display_name: Yup.string().label("display name").max(50).nullable(),
  username: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, {
      message: "Alphanumeric characters only",
      excludeEmptyString: true,
    })
    .max(25)
    .required(),
  bio: Yup.string().max(200).nullable(),
  website: Yup.string().max(255).nullable(),
});

const initialState = {
  display_name: "",
  username: "",
  bio: "",
  website: "",
};

function Account(): ReactElement {
  const { session, user } = Auth.useUser();
  const { t } = useTranslation();

  const profile = useOwnProfile();
  const updateProfile = useUpdateProfile();

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: initialState,
    resolver: yupResolver(ProfileSchema),
  });
  const watchBio = useWatch({
    control,
    name: "bio",
    defaultValue: "",
  });

  const onSubmit = async (input: ProfileType) => {
    updateProfile.mutate(
      _pick(input, Object.keys(initialState)) as ProfileType,
      {
        onSuccess: (data) => {
          reset(_pick(data, Object.keys(initialState)));
          toast.success(t("Saved"));
        },
        onError: (e) => {
          toast.error(t(formatServerError(e as ServerError)));
        },
      }
    );
  };

  useEffect(() => {
    if (!profile.isLoading && profile.data) {
      reset({
        ...initialState,
        ..._pick(profile?.data, Object.keys(initialState)),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.isLoading, profile.data]);

  const isEmailProvider = session?.user?.app_metadata?.provider === "email";

  if (profile.isLoading) {
    return <Spinner fullScreen />;
  }

  if (!profile.isLoading && profile.error) {
    return <Error500 className="my-10" />;
  }

  return (
    <>
      <form id="profile-form" onSubmit={handleSubmit(onSubmit)}>
        <nav aria-label="breadcrumb" className="text-sm">
          <ul className="inline-flex items-center space-x-2">
            <li className="inline-flex items-center">
              <LibraryIcon className="mr-2 w-4 h-4" />
              <Link to="/dashboard">{t("My Trove")}</Link>
            </li>
            <li aria-current="page" className="inline-flex items-center">
              <ChevronRightIcon className="mr-2 w-4 h-4" />
              {t("Profile")}
            </li>
          </ul>
        </nav>
        <h2 className="mb-8">{t("Profile")}</h2>
        <div className="form-row">
          <div className="w-full form-group">
            <label htmlFor="display_name">{t("Display name")}</label>
            <input
              id="display_name"
              type="text"
              aria-invalid={errors.display_name ? "true" : "false"}
              {...register("display_name")}
              className={clsx(errors.display_name && "border-red-500")}
            />
            {errors?.display_name?.message ? (
              <p className="form-hint error">{errors.display_name.message}</p>
            ) : (
              <p className="form-hint">
                {t("This will be shown on public profiles.")}
              </p>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="w-full form-group">
            <label htmlFor="username">
              {t("Username")}
              <span>*</span>
            </label>
            <input
              id="username"
              type="text"
              aria-invalid={errors.username ? "true" : "false"}
              {...register("username")}
              className={clsx(errors.username && "border-red-500")}
            />
            {errors?.username?.message ? (
              <p className="form-hint error">{errors.username.message}</p>
            ) : (
              <p className="form-hint">
                {t("A username is required for public profiles.")}
              </p>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="w-full form-group">
            <label htmlFor="bio">{t("Bio")}</label>
            <textarea
              id="bio"
              maxLength={200}
              aria-invalid={errors.bio ? "true" : "false"}
              {...register("bio")}
              className={clsx(errors.bio && "border-red-500")}
            />
            <p
              className={clsx(
                "text-right form-hint",
                watchBio?.length / 200 > 0.9
                  ? "text-red-500"
                  : watchBio?.length / 200 > 0.75
                  ? "text-yellow-400"
                  : null
              )}
            >
              {watchBio?.length || 0}/200
            </p>
          </div>
        </div>
        <div className="form-row">
          <div className="w-full form-group">
            <label htmlFor="website">{t("Website")}</label>
            <input
              id="website"
              type="text"
              {...register("website")}
              className={clsx(errors.website && "border-red-500")}
            />
            {errors?.website?.message && (
              <p className="form-hint error">{errors.website.message}</p>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="w-full form-group">
            <label htmlFor="avatar">{t("Avatar")}</label>
            <Gravatar className="mb-2" email={user?.email} />
            <p className="form-hint">
              <a
                href="https://www.gravatar.com"
                target="_blank"
                rel="noreferrer"
              >
                {t("Customise your avatar on Gravatar")}
              </a>
            </p>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-sm btn-solid btn-ink"
          disabled={!isDirty || isSubmitting || updateProfile.isLoading}
          title={!isDirty ? t("No changes to save.") : undefined}
          form="profile-form"
        >
          <span>{t("Save")}</span>
          <span
            className={clsx(
              "ml-2 w-4 h-4 border-2 loader inverse",
              !isSubmitting && !updateProfile.isLoading && "hidden"
            )}
          />
        </button>
      </form>
      {/* TODO ADD SUPPORT FOR CUSTOM AVATAR INSTEAD */}
      {isEmailProvider && (
        <>
          <ChangeEmailForm />
          <ChangePasswordForm />
        </>
      )}
    </>
  );
}

export default Account;
