import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import CWSButton from "assets/images/cws_button.png";

function Tools(): ReactElement {
  const { t } = useTranslation();
  return (
    <article>
      <h1>{t("Tools")}</h1>

      <div className="flex flex-wrap">
        <div className="p-6 w-full sm:w-1/2">
          <h2 className="mb-3 text-xl font-bold leading-none text-gray-800">
            Chrome Extension
          </h2>
          <p>
            Use our Chrome Extension for faster bookmarking. Tag your finds and
            add them to collections on the go.
          </p>
        </div>
        <div className="p-6 w-full sm:w-1/2">
          <a href="https://chrome.google.com/webstore/detail/thesaurus-inventus/ngjpnbpiioibbibebepcbbmondjckajg">
            <img
              src={CWSButton}
              alt={t("Chrome Web Store Button")}
              className="z-50 mx-auto w-full md:w-3/5 h-auto"
            />
          </a>
        </div>
      </div>
    </article>
  );
}

export default Tools;
