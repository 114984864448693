import React, { ReactElement, useEffect } from "react";
import mailgo from "mailgo";
import { Helmet } from "react-helmet-async";

const { REACT_APP_SUPPORT_EMAIL } = process.env;

function Privacy(): ReactElement {
  useEffect(() => {
    mailgo();
  }, []);

  const title = "Privacy Policy";
  const description = "Privacy Policy for Thesaurus Inventus.";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <article>
        <h1>Privacy Policy</h1>
        <p>
          Your privacy is important to us. It is Tourbillon Labs Pty Ltd's
          policy to respect your privacy regarding any information we may
          collect from you across our website, thesaurusinventus.com, and other
          sites we own and operate.
        </p>
        <h2>Personal Data</h2>
        <p>
          We only ask for personal information when we truly need it to provide
          a service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we're collecting it
          and how it will be used.
        </p>
        <p>
          While using our service, we may ask you to provide personally
          identifiable information that can be used to contact or identify you.
          This information may include, but is not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First and last name</li>
          <li>Usage data</li>
        </ul>
        <h2>Usage data</h2>
        <p>Usage data is collected automatically when you use our service.</p>
        <p>
          Usage data may include information such as your IP address, browser
          type, browser version, pages of our service that you visit, and other
          diagnostic data.
        </p>
        <h2>Third-Party Social Media Services</h2>
        <p>
          In addition to email authentication, we offer authentication to our
          service using Google and Facebook. If you choose to register for our
          service with a third-party social media account, we may collect
          personal data such as your name and email address associated with your
          social media account.
        </p>
        <h2>Use of Personal Data</h2>
        <p>Personal data may be used for the following purposes:</p>
        <ul className="mb-4">
          <li>To provide and maintain our service.</li>
          <li>
            To manage your account which gives you access to our service and its
            related features.
          </li>
          <li>
            To contact you via email regarding updates to our products or
            services.
          </li>
          <li>
            To provide you with offers and general information about our
            products or services unless you have opted not to receive such
            information.
          </li>
          <li>To manage your communications with us.</li>
          <li>
            For data analysis to improve our products and services, and your
            user experience.
          </li>
        </ul>
        <p>
          Should you choose to have a public profile with our service, the
          information you have chosen to share in your profile on our service
          may be viewed by other users and may be publicly accessible.
        </p>
        <h2>Third-Party Vendors</h2>
        <p>
          The service providers we use may have access to your personal data.
        </p>
        <h3>Ko-Fi</h3>
        <p>
          Ko-Fi is a crowdfunding platform which allows creators to accept
          donations and memberships for their work. For more information on the
          privacy practices of Ko-Fi, please visit their privacy policy at{" "}
          <a
            href="https://more.ko-fi.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://more.ko-fi.com/privacy
          </a>
        </p>
        <p>
          We offer Ko-Fi as an option for our fans and members to support our
          service.
        </p>
        <h2>Information Access</h2>
        <p>
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we'll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorised access, disclosure, copying, use or
          modification.
        </p>
        <p>
          We don't share any personally identifying information publicly or with
          third-parties, except when required to by law.
        </p>
        <p>
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and practices
          of these sites, and cannot accept responsibility or liability for
          their respective privacy policies.
        </p>
        <p>
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          your desired services.
        </p>
        <p>
          Your continued use of our website will be regarded as acceptance of
          our practices around privacy and personal information. If you have any
          questions about how we handle user data and personal information, feel
          free to <a href={`mailto:${REACT_APP_SUPPORT_EMAIL}`}>contact us</a>.
        </p>
        <hr className="my-4" />
        <p>This policy is effective as of 28 November, 2021.</p>
      </article>
    </>
  );
}

export default Privacy;
