import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import Kofi from "components/Kofi";

import Typewriter from "assets/svg/typewriter.svg";
import SaveBookmarks from "assets/svg/save_bookmarks.svg";
import ViewCollections from "assets/svg/booking.svg";
import WithLove from "assets/svg/with_love.svg";
import { Helmet } from "react-helmet-async";

function About(): ReactElement {
  const { t } = useTranslation();

  const title = "About Thesaurus Inventus";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <article>
        <h1>{title}</h1>

        <div className="flex flex-wrap">
          <div className="p-6 w-full sm:w-1/2">
            <h2 className="mb-3 text-xl font-bold leading-none text-gray-800">
              Made for writers
            </h2>
            <p>
              Whether you are writing, researching, or finding your voice,
              you&lsquo;re always refining your craft.
            </p>
            <p>
              When you&lsquo;re not procrastination cleaning or working your way
              through a sleeve of biscuits to give your ideas &lsquo;time to
              marinate&rsquo;, anyway.
            </p>
          </div>
          <div className="p-6 w-full sm:w-1/2">
            <img
              src={Typewriter}
              alt={t("Typewriter")}
              className="z-50 mx-auto w-full md:w-3/5 h-auto"
            />
          </div>
        </div>

        <div className="flex flex-col-reverse sm:flex-row flex-wrap">
          <div className="p-6 md:mt-6 w-full sm:w-1/2">
            <img
              src={SaveBookmarks}
              alt={t("Save bookmarks")}
              className="z-50 mx-auto w-full md:w-3/5 h-auto"
            />
          </div>
          <div className="p-6 md:mt-6 w-full sm:w-1/2">
            <div className="align-middle">
              <h2 className="mb-3 text-xl font-bold leading-none text-gray-800">
                Save bookmarks
              </h2>
              <p>
                Collect links to fill out those plot details, or exercises that
                can help you get over your writer&lsquo;s block or improve your
                writing style.
              </p>
              <p>
                Create collections to put the resources for each of your
                projects in one place.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="p-6 md:mt-6 w-full sm:w-1/2">
            <h2 className="mb-3 text-xl font-bold leading-none text-gray-800">
              Share with others
            </h2>
            <p>
              Keep your collections or bookmarks private, or tag and share them
              with other like-minded writers.
            </p>
            <p>
              See what other Victorian era black market crime-romance writers
              found useful for their project.
            </p>
          </div>
          <div className="p-6 md:mt-6 w-full sm:w-1/2">
            <img
              src={ViewCollections}
              alt={t("View community shares")}
              className="z-50 mx-auto w-full md:w-3/5 h-auto"
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row p-4 my-16 mx-auto max-w-md lg:max-w-3xl rounded-lg shadow-lg card">
          <div className="lg:p-4 w-full lg:w-1/4">
            <img
              src={WithLove}
              alt={t("With Love")}
              className="mb-3 w-full h-32 lg:h-full"
            />
          </div>
          <div className="lg:self-center lg:p-4 pt-4 lg:pt-0 w-full lg:w-3/4">
            <p>
              <strong>Thesaurus Inventus</strong> was created as a space to
              collect and share writing resources that are fun, fascinating,
              helpful, or thought-provoking.
            </p>

            <p className="mb-0">
              It&lsquo;s something that we want to use, and hope others might as
              well.
            </p>
          </div>
        </div>

        <h2>Why Thesaurus Inventus?</h2>

        <div className="p-3 m-auto max-w-md card">
          <dl>
            <dt className="font-playfair text-lg font-bold text-gray-800">
              thesaurus inventus
            </dt>
            <dd className="text-sm italic">
              (n.) Latin for "treasure found" or a "treasure trove". A legal
              term in property law.
            </dd>
          </dl>
        </div>

        <h3>Yes, but why?</h3>

        <p>Treasure Trove was taken and the Latin sounds nice.</p>

        <h2>Support Us</h2>
        <p>
          <strong>Thesaurus Inventus</strong> is free to use, but if you like
          what we do and want to help keep the lights on, please become a
          patron. Both once-off or monthly options are available and we'd{" "}
          <em>really</em> appreciate your patronage.
        </p>
        <p>
          If enough of you like it that we need to review our infrastructure,
          we'll introduce a paid tier with a few extra features. We'll figure
          something out for our patrons as well. :)
        </p>
        <Kofi />
        <p className="text-xs">
          Illustrations by{" "}
          <a href="https://undraw.co/" target="_blank" rel="noreferrer">
            undraw.co
          </a>
        </p>
      </article>
    </>
  );
}

export default About;
