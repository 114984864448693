import { Auth } from "@supabase/ui";
import { ReactElement } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { AppProvider } from "services/AppContext";
import supabase from "services/supabase";
import Routes from "routes";

import "./App.css";

function App(): ReactElement {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 60 * 1000,
      },
    },
  });

  return (
    <AppProvider>
      <Auth.UserContextProvider supabaseClient={supabase}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename="/">
            <Routes />
          </BrowserRouter>
        </QueryClientProvider>
      </Auth.UserContextProvider>
    </AppProvider>
  );
}

export default App;
