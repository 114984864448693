import { useCallback } from "react";
import {
  MutationFunction,
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  UseInfiniteQueryResult,
  UseQueryResult,
} from "react-query/types/react/types";

import bookmarkActions, {
  bookmarkKeys,
  linkKeys,
  LinkMetaType,
  BookmarksSuccessCallback,
  BookmarkViewType,
  PagedBookmarksType,
} from "bookmarks/actions";
import { CollectionType } from "collections/actions";
import supabase from "services/supabase";

export function useOwnBookmarkByShortId(
  short_id?: string
): UseQueryResult<BookmarkViewType> {
  return useQuery(
    bookmarkKeys.detail(short_id ?? "unknown", { own: true }),
    () => bookmarkActions.getOwnBookmarkByShortId(short_id),
    {
      enabled: !!short_id && short_id.length > 0,
    }
  );
}

export function usePublicBookmarks(
  successCallback: BookmarksSuccessCallback
): UseInfiniteQueryResult<BookmarkViewType[]> {
  return useInfiniteQuery(
    bookmarkKeys.list("public"),
    ({ pageParam = 1 }) => bookmarkActions.getPublicBookmarks(pageParam),
    {
      getNextPageParam: (data: PagedBookmarksType) => data.nextPage ?? false,
      onSuccess: useCallback((data) => {
        successCallback &&
          successCallback(data.pages[data.pages.length - 1].results);
        return data;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []),
    }
  );
}

export function useUserBookmarkByShortId(
  short_id: string,
  successCallback?: BookmarksSuccessCallback
): UseQueryResult<BookmarkViewType> {
  return useQuery(
    bookmarkKeys.detail(short_id),
    () => bookmarkActions.getUserBookmarkByShortId(short_id),
    {
      onSuccess: useCallback((data) => {
        successCallback && successCallback([data]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []),
    }
  );
}

export function useUserBookmarks(
  id: string,
  successCallback?: BookmarksSuccessCallback
): UseInfiniteQueryResult {
  return useInfiniteQuery(
    bookmarkKeys.list(id),
    ({ pageParam = 1 }) => bookmarkActions.getUserBookmarks(id, pageParam),
    {
      getNextPageParam: (data: PagedBookmarksType) => data.nextPage ?? false,
      onSuccess: useCallback((data) => {
        successCallback &&
          successCallback(data.pages[data.pages.length - 1].results);
        return data;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []),
      enabled: !!id,
    }
  );
}

export function useBookmarkCollections<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends any = CollectionType[] | number[]
>(id?: number, select?: (data: CollectionType[]) => T): UseQueryResult {
  return useQuery(
    bookmarkKeys.detail(id ?? "unknown", { collections: true }),
    () => bookmarkActions.getBookmarkCollections({ id }),
    {
      enabled: !!id,
      select,
    }
  );
}

export function useSearch(query: string): UseInfiniteQueryResult {
  return useInfiniteQuery(
    bookmarkKeys.list("search", query),
    ({ pageParam = 1 }) => bookmarkActions.getSearchResults(query, pageParam),
    {
      getNextPageParam: (data: PagedBookmarksType) => data.nextPage ?? false,
      enabled: !!query && query !== "",
    }
  );
}

export function useBookmarkUpsert(): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(
    bookmarkActions.upsertBookmark as MutationFunction<BookmarkViewType>,
    {
      onSuccess: (bookmark: BookmarkViewType) => {
        queryClient.invalidateQueries(
          linkKeys.detail(encodeURIComponent(bookmark.url as string))
        );
        queryClient.invalidateQueries(
          bookmarkKeys.list(bookmark.user_id as string)
        );
      },
    }
  );
}

export function useBookmarkDelete(): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(bookmarkActions.deleteBookmark as MutationFunction<void>, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        bookmarkKeys.list(supabase?.auth?.user()?.id as string)
      );
    },
  });
}

export function useLinkMeta(link: string): UseQueryResult<LinkMetaType> {
  return useQuery(
    linkKeys.detail(encodeURIComponent(link)),
    () => bookmarkActions.fetchLinkMeta(link),
    {
      enabled: false,
    }
  );
}
