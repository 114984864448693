import { Auth } from "@supabase/ui";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import UserDropdown from "auth/components/UserDropdown";
import Brand from "components/Brand";
import NavDropdown from "./NavDropdown";

function Nav(): ReactElement {
  const { t } = useTranslation();
  const { session } = Auth.useUser();

  return (
    <nav className="flex items-center py-3 text-xs md:text-sm leading-5">
      <Brand />
      <div className="flex flex-shrink-0 items-center ml-auto space-x-2 md:space-x-4">
        <NavDropdown />
        {session ? (
          <UserDropdown />
        ) : (
          <>
            <Link className="btn btn-sm btn-outline" to="/register">
              {t("Sign up")}
            </Link>
            <Link className="text-sm" to="/login">
              {t("Log in")}
            </Link>
          </>
        )}
      </div>
    </nav>
  );
}

export default Nav;
