import _isUndefined from "lodash/isUndefined";
import queryString from "query-string";
import { ReactElement } from "react";
import React from "react";
import { Switch, useLocation } from "react-router-dom";

import Account from "auth/pages/Account";
import Dashboard from "auth/pages/Dashboard";
import Login from "auth/pages/Login";
import Profile from "auth/pages/Profile";
import Home from "pages/Home";
import About from "pages/About";
import Tools from "pages/Tools";
import Privacy from "pages/Privacy";
import Terms from "pages/Terms";
import TagView from "tags/pages/View";
import BookmarkExtension from "bookmarks/pages/Extension";
import BookmarkForm from "bookmarks/pages/Form";
import BookmarkView from "bookmarks/pages/View";
import CollectionForm from "collections/pages/Form";
import CollectionView from "collections/pages/View";
import Page404 from "pages/404";
import ExtensionHelper from "components/ExtensionHelper";

import PrivateRoute from "layouts/PrivateRoute";
import DefaultRoute from "layouts/DefaultRoute";

function Routes(): ReactElement {
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const isExt = !_isUndefined(qs.ext) || window.opener;

  return (
    <Switch>
      <DefaultRoute exact path="/" component={Home} showFab />
      <DefaultRoute exact path="/about" component={About} showFab />
      <DefaultRoute exact path="/tools" component={Tools} showFab />
      <DefaultRoute exact path="/privacy" component={Privacy} />
      <DefaultRoute exact path="/terms" component={Terms} />
      <DefaultRoute exact path="/login" component={Login} isExt={isExt} />
      <DefaultRoute exact path="/register" component={Login} />
      <DefaultRoute exact path="/confirmed" component={ExtensionHelper} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} showFab />
      <DefaultRoute exact path="/@:username" component={Profile} showFab />
      <DefaultRoute exact path="/tags/:tag" component={TagView} showFab />
      <PrivateRoute exact path="/account" component={Account} />
      <PrivateRoute
        exact
        path="/bookmarks/add"
        component={isExt ? BookmarkExtension : BookmarkForm}
        isExt={isExt}
      />
      <DefaultRoute
        exact
        path="/bookmarks/:short_id"
        component={BookmarkView}
        showFab
      />
      <PrivateRoute
        exact
        path="/bookmarks/:short_id/edit"
        component={BookmarkForm}
        showFab
      />
      <PrivateRoute exact path="/collections/add" component={CollectionForm} />
      <DefaultRoute
        exact
        path="/@:username/:slug"
        component={CollectionView}
        showFab
      />
      <PrivateRoute
        exact
        path="/collections/:slug"
        component={CollectionView}
        showFab
      />
      <PrivateRoute
        exact
        path="/collections/:slug/edit"
        component={CollectionForm}
        showFab
      />
      <DefaultRoute component={Page404} />
    </Switch>
  );
}

export default Routes;
