import _pick from "lodash/pick";

import {
  BookmarkType,
  BookmarkViewType,
  PagedBookmarksType,
} from "bookmarks/actions";
import supabase from "services/supabase";
import { ServerError } from "utils/RequestError";
import { getPagination } from "utils/helpers";
import { PAGE_SIZE } from "../constants";

export interface CollectionType {
  id?: number;
  slug?: string;
  name: string;
  user_id?: string;
  username?: string;
  is_private?: boolean;
  created_at?: string;
}

export type BookmarkCollectionType = BookmarkType & {
  collection_id?: number;
  collection_name?: string;
  collection_is_private?: string;
};

const VALID_EDITABLE_COLLECTION_KEYS = ["name", "is_private"];

export const collectionKeys = {
  all: ["collections"] as const,
  lists: () => [...collectionKeys.all, "list"] as const,
  list: (filters: string | number) =>
    [...collectionKeys.lists(), { filters }] as const,
  details: () => [...collectionKeys.all, "detail"] as const,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  detail: (
    user: string,
    id: string | number,
    options?: { bookmarks?: boolean }
  ) =>
    options?.bookmarks
      ? ([...collectionKeys.details(), "collections", user, id] as const)
      : ([...collectionKeys.details(), user, id] as const),
};

//
// Individual Collection
//

async function getCollectionBySlug(
  slug: string,
  username?: string
): Promise<CollectionType> {
  if (!slug) {
    throw new ServerError({ status: 400, message: "Slug is required." });
  }
  if (!username) {
    return getOwnCollectionBySlug(slug);
  } else {
    return getUserCollectionBySlug(slug, username);
  }
}

async function getOwnCollectionBySlug(slug: string): Promise<CollectionType> {
  if (!slug) {
    throw new ServerError({ status: 400, message: "Slug is required." });
  }
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const { data, error, status } = await supabase
    .from("collections")
    .select("*")
    .match({ slug, user_id: supabase?.auth?.user()?.id });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

async function getUserCollectionBySlug(
  slug: string,
  username: string
): Promise<CollectionType> {
  if (!slug) {
    throw new ServerError({ status: 400, message: "Slug is required." });
  }
  if (!username) {
    throw new ServerError({ status: 400, message: "Username is required." });
  }
  const { data, error, status } = await supabase
    .from("user_collections_view")
    .select("*")
    .match({ slug, username, is_private: false });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

//
// Multiple Collections
//

async function getUserCollections(id?: string): Promise<CollectionType[]> {
  if (!id) {
    throw new ServerError({ status: 400, message: "User ID is required." });
  }
  const match: { user_id: string; is_private?: boolean } = { user_id: id };
  if (!supabase?.auth?.session() || supabase?.auth?.user()?.id !== id) {
    match.is_private = false;
  }
  const { data, error, status } = await supabase
    .from("collections")
    .select("*")
    .match(match)
    .order("name", { ascending: true });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ?? [];
  }
}

//
// Collection Bookmarks
//

async function getCollectionBookmarks(
  collection: CollectionType,
  page = 1
): Promise<PagedBookmarksType> {
  if (!collection) {
    throw new ServerError({ status: 400, message: "Collection is required." });
  }
  const match: { collection_id: number; is_private?: boolean } = {
    collection_id: Number(collection.id),
  };
  if (
    !(
      supabase?.auth?.session() &&
      collection.user_id === supabase?.auth?.user()?.id
    )
  ) {
    match.is_private = false;
  }
  const range = getPagination(page);
  const { data, error, status } = await supabase
    .from("user_collection_bookmarks_view")
    .select("*")
    .match(match)
    .order("updated_at", { ascending: false })
    .range(range.from, range.to);
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return {
      results: data ?? [],
      nextPage: data && data.length === PAGE_SIZE ? page + 1 : undefined,
    };
  }
}

async function insertCollection(
  input: CollectionType
): Promise<CollectionType> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const { data, error, status } = await supabase.from("collections").insert({
    is_private: true,
    ..._pick(input, VALID_EDITABLE_COLLECTION_KEYS),
  });
  if (error) {
    switch (error.code) {
      case "23505":
        throw new ServerError({
          status,
          code: error.code,
          message: `You have already added this collection.`,
        });
      default:
        throw new ServerError({ status, code: error.code });
    }
  } else {
    return data ? data[0] : undefined;
  }
}

async function updateCollection(
  id: number,
  input: CollectionType
): Promise<CollectionType> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const { data, error, status } = await supabase
    .from("collections")
    .update(_pick(input, VALID_EDITABLE_COLLECTION_KEYS))
    .match({ id, user_id: supabase?.auth?.user()?.id });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

async function upsertCollection(
  input: CollectionType
): Promise<CollectionType> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  let collection;
  if (!input.id) {
    collection = await insertCollection(
      _pick(input, VALID_EDITABLE_COLLECTION_KEYS) as CollectionType
    );
  } else {
    collection = await updateCollection(
      input.id,
      _pick(input, VALID_EDITABLE_COLLECTION_KEYS) as CollectionType
    );
  }

  return collection;
}

async function deleteCollection(id: number): Promise<void> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  if (!id) {
    throw new ServerError({
      status: 400,
      message: "Collection ID is required.",
    });
  }
  const { error, status } = await supabase
    .from("collections")
    .delete()
    .match({ id, user_id: supabase?.auth?.user()?.id });
  if (error) {
    throw new ServerError({ status, code: error.code });
  }
}

async function upsertBookmarkCollections(
  bookmark: BookmarkViewType,
  collections: number[]
): Promise<boolean> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  if (!bookmark || !collections) {
    throw new ServerError({
      status: 400,
      message: "Bookmark or collections are missing.",
    });
  }
  const current = await supabase
    .from("collections_bookmarks")
    .select("collection_id")
    .match({ bookmark_id: bookmark.id });
  if (current.error) {
    throw new ServerError({ status: current.status, code: current.error.code });
  }
  const oldCollections = current.data.map((c) => c.collection_id);
  const r = oldCollections.filter((x) => !collections.includes(x));
  const a = collections.filter((x) => !oldCollections.includes(x));
  if (r.length > 0) {
    const removed = await supabase
      .from("collections_bookmarks")
      .delete()
      .eq("bookmark_id", bookmark.id)
      .in("collection_id", r);
    if (removed.error) {
      throw new ServerError({
        status: removed.status,
        code: removed.error.code,
      });
    }
  }
  if (a.length > 0) {
    const added = await supabase
      .from("collections_bookmarks")
      .insert(a.map((x) => ({ bookmark_id: bookmark.id, collection_id: x })));
    if (added.error) {
      throw new ServerError({ status: added.status, code: added.error.code });
    }
  }

  return true;
}

const actions = {
  getCollectionBySlug,
  getOwnCollectionBySlug,
  getUserCollectionBySlug,
  getUserCollections,
  getCollectionBookmarks,
  insertCollection,
  upsertCollection,
  deleteCollection,
  upsertBookmarkCollections,
};

export default actions;
