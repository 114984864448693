import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "@supabase/ui";
import clsx from "clsx";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { useChangeEmail } from "auth/hooks";
import { ServerError } from "utils/RequestError";
import { formatServerError } from "utils/helpers";

type FormValues = {
  email: string;
};

const EmailSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

function ChangeEmailForm(): ReactElement {
  const { t } = useTranslation();
  const { user } = Auth.useUser();
  const changeEmail = useChangeEmail();

  const onSubmit = async (input: FormValues) => {
    if (input.email === user?.email) {
      setError("email", {
        type: "unchanged",
        message: t("Email is unchanged."),
      });
      return false;
    }
    changeEmail.mutate(input.email, {
      onSuccess: () => {
        toast.success(t("Please check both emails to confirm the change."), {
          position: "bottom-left",
          duration: 4000,
        });
      },
      onError: (e) => {
        toast.error(t(formatServerError(e as ServerError)));
      },
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    setError,
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: { email: user?.email },
    resolver: yupResolver(EmailSchema),
  });

  return (
    <form
      id="email-form"
      className="mt-0 border-t"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="mb-8">{t("Account")}</h2>
      <div className="mt-8 form-row">
        <div className="w-full form-group">
          <label htmlFor="email">
            {t("Email")}
            <span>*</span>
          </label>
          <div className="flex gap-2 mb-3">
            <input
              id="email"
              type="text"
              {...register("email")}
              className="mb-0"
            />
            <button
              type="submit"
              form="email-form"
              disabled={!isDirty || isSubmitting || changeEmail.isLoading}
              className="flex-shrink-0 leading-none btn btn-sm btn-solid btn-ink"
            >
              <span>{t("Change email")}</span>
              <span
                className={clsx(
                  "ml-2 w-4 h-4 border-2 loader inverse",
                  !isSubmitting && !changeEmail.isLoading && "hidden"
                )}
              />
            </button>
          </div>
          {errors?.email?.message ? (
            <p className="form-hint error">{errors.email.message}</p>
          ) : (
            <p className="form-hint">
              {t(
                "Warning: Changing your email will require confirmation from both email addresses for the change to take effect."
              )}
            </p>
          )}
        </div>
      </div>
    </form>
  );
}

export default ChangeEmailForm;
