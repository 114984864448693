import { Auth } from "@supabase/ui";
import React, { ReactElement } from "react";
import { UseQueryResult } from "react-query/types/react/types";

import { BookmarksSuccessCallback } from "bookmarks/actions";
import { useUserBookmarks } from "bookmarks/hooks";
import { CollectionType } from "collections/actions";
import { useUserCollections } from "collections/hooks";
import BookmarkGrid, { InfiniteBookmarksResult } from "components/BookmarkGrid";
import CollectionList from "components/CollectionList";
import Spinner from "components/Spinner";
import { useApp } from "services/AppContext";

function Dashboard(): ReactElement {
  const { user } = Auth.useUser();
  const { updateBookmarkStore } = useApp();

  const collections: UseQueryResult = useUserCollections(user?.id as string);
  const bookmarks = useUserBookmarks(
    user?.id as string,
    updateBookmarkStore as BookmarksSuccessCallback
  );

  if (collections.isLoading || bookmarks.isLoading) {
    return <Spinner className="mx-auto mt-10" size="lg" />;
  }

  return (
    <div className="grid grid-cols-4 p-6 sm:px-6 lg:px-16 space-y-8 md:space-y-0 space-x-4">
      <div className="col-span-full md:col-span-1">
        <CollectionList
          collections={(collections.data ?? []) as CollectionType[]}
        />
      </div>
      <div className="col-span-full md:col-span-3">
        <BookmarkGrid bookmarks={bookmarks as InfiniteBookmarksResult} />
      </div>
    </div>
  );
}

export default Dashboard;
