import { Auth } from "@supabase/ui";
import clsx from "clsx";
import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";
import { ReactElement, memo, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

import { BookmarkViewType } from "bookmarks/actions";
import { useApp } from "services/AppContext";

import BookmarkIcon from "assets/icons/bookmark";
import ExternalLinkIcon from "assets/icons/external-link";
import PencilIcon from "assets/icons/pencil";
import PhotographIcon from "assets/icons/photograph";
import VisibleIcon from "assets/icons/visible";
import { slugify } from "utils/helpers";

interface PreviewProps {
  bookmark: BookmarkViewType & { tags?: string[] };
  showActions?: boolean;
}

function Preview({
  bookmark,
  showActions = false,
}: PreviewProps): ReactElement {
  const { session } = Auth.useUser();
  const { link_id, url, title, description, image_url, tags } = bookmark;
  const { bookmarkStore } = useApp();
  const history = useHistory();
  const { t } = useTranslation();

  const [showFallback, setShowFallback] = useState(false);

  const mine = link_id
    ? (bookmarkStore as Map<number, BookmarkViewType>).get(link_id)
    : undefined;

  const saveBookmark = () => {
    if (!session) {
      toast.error(t("You must be logged in to save bookmarks."));
      return;
    }
    history.push("/bookmarks/add", {
      prefill: _pick(bookmark, ["url", "title", "description", "image_url"]),
    });
  };

  let hostname;
  if (url && url !== "") {
    try {
      hostname = new URL(url).hostname;
    } catch (e) {}
  }

  let image = (
    <>
      {!showFallback && image_url && (
        <img
          src={image_url}
          className="object-cover absolute w-full h-full rounded-t-sm"
          alt={title}
          onError={() => setShowFallback(true)}
        />
      )}
      {(showFallback || !image_url) && !!title && (
        <div className="flex absolute items-center p-6 w-full h-full bg-ink titlecard">
          <h3
            className={clsx(
              "w-full leading-normal text-whitesmoke line-clamp-3",
              "text-3xl"
            )}
          >
            {title}
          </h3>
        </div>
      )}
      {(showFallback || !image_url) && !title && description && (
        <div className="flex absolute items-center p-6 w-full h-full bg-ink titlecard">
          <h3
            className={clsx(
              "w-full leading-normal text-whitesmoke line-clamp-3",
              "text-3xl"
            )}
          >
            {description}
          </h3>
        </div>
      )}
      {!image_url && !title && !description && (
        <div className="flex absolute justify-center items-center w-full h-full">
          <PhotographIcon className="w-12 h-12" />
        </div>
      )}
    </>
  );
  if (url) {
    image = (
      <a href={url} target="_blank" rel="noreferrer">
        {image}
      </a>
    );
  }

  return (
    <div className="flex flex-col mb-6 card">
      <div className="group relative">
        <div className="relative" style={{ paddingBottom: "56.2%" }}>
          {image}
        </div>
        {showActions && session && (
          <div className="hidden group-hover:flex absolute top-3 right-3 py-1.5 px-1.5 space-x-2 leading-none text-right text-white bg-black bg-opacity-50">
            {mine?.short_id && (
              <Link className="inline-block" to={`/bookmarks/${mine.short_id}`}>
                <VisibleIcon className="w-4 h-4 text-white" />
              </Link>
            )}
            {mine?.short_id && (
              <Link
                className="inline-block"
                to={`/bookmarks/${mine.short_id}/edit`}
              >
                <PencilIcon className="w-4 h-4 text-white" />
              </Link>
            )}
            {session && !mine?.short_id && (
              <button onClick={() => saveBookmark()}>
                <BookmarkIcon className="w-4 h-4 text-white" />
              </button>
            )}
          </div>
        )}
      </div>
      {(title || description || url) && (
        <div className="flex flex-col flex-grow m-2">
          {title && (
            <h5>
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {title}
                </a>
              ) : (
                title
              )}
            </h5>
          )}
          {description && <p className="text-sm line-clamp-3">{description}</p>}
          {url && (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="mt-auto break-all line-clamp-1"
            >
              <ExternalLinkIcon className="inline-block mr-1 w-3 h-3" />
              <span className="text-sm">{hostname}</span>
            </a>
          )}
          {tags && (
            <div className="mt-1 space-x-1 line-clamp-1">
              {tags.map((t) => {
                const keyname = slugify(t);
                return (
                  <div key={`${link_id}-${keyname}`} className="chip">
                    <Link to={`/tags/${keyname}`}>{t}</Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const MemoisedPreview = memo(Preview, (prevProps, nextProps) =>
  _isEqual(prevProps.bookmark, nextProps.bookmark)
);

export default MemoisedPreview;
