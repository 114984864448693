import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LibraryIcon from "assets/icons/library";
import SparkleIcon from "assets/icons/sparkle";

function Hero(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap items-center sm:pt-4 pb-10 sm:pb-14">
      <div className="flex-none xl:flex-auto mt-10 w-full xl:w-auto text-center xl:text-left">
        <h1 className="text-3xl sm:text-4xl font-semibold leading-9 sm:leading-10 text-gray-800">
          {t("Your treasure trove of writing resources")}
        </h1>
        <p className="my-3">
          {t(
            "Save links to resources to detail that plot, flesh out that backstory, or improve your writing style."
          )}
        </p>
      </div>
      <div className="flex flex-col-reverse sm:flex-row flex-none sm:items-start mx-auto xl:mx-0 mt-10 space-y-3 sm:space-y-0 sm:space-x-4 space-y-reverse w-full sm:w-auto">
        <Link className="z-0 btn btn-solid btn-ink" to="/register">
          <SparkleIcon className="w-5 h-5" />
          <span>{t("Get Started")}</span>
        </Link>
        <Link className="z-0 btn btn-solid btn-ink-100" to="/about">
          <LibraryIcon className="w-5 h-5" />
          <span>{t("Learn More")}</span>
        </Link>
      </div>
    </div>
  );
}

export default Hero;
