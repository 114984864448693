import clsx from "clsx";
import { memo, ReactElement, ReactNode } from "react";

import XIcon from "assets/icons/x";

interface ModalProps {
  title: string;
  children: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  showCancel?: boolean;
  confirmClassName?: string;
  modalClassName?: string;
}

function Modal({
  title = "",
  children,
  onConfirm,
  onCancel,
  confirmText = "Confirm",
  cancelText = "Cancel",
  showCancel = true,
  confirmClassName = "bg-ink text-white",
  modalClassName,
}: ModalProps): ReactElement {
  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <>
      <div className="flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 justify-center items-center outline-none focus:outline-none">
        <div
          className={clsx(
            "relative my-6 mx-auto w-auto max-w-3xl",
            modalClassName
          )}
        >
          <div className="flex relative flex-col w-full bg-white rounded border-0 shadow-lg outline-none focus:outline-none">
            <div className="flex justify-between items-start p-4 pb-2 rounded-t">
              <h3 className="m-0 font-sans text-base font-semibold">{title}</h3>
              <button
                className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent hover:bg-gray-100 border-0 outline-none focus:outline-none"
                onClick={() => handleCancel()}
              >
                <XIcon className="w-4 h-4" />
              </button>
            </div>
            <div className="relative flex-auto p-4 pt-2">{children}</div>
            <div className="flex justify-end items-center p-4 rounded-b">
              {showCancel && (
                <button
                  className="btn btn-sm"
                  type="button"
                  onClick={() => handleCancel()}
                >
                  {cancelText}
                </button>
              )}
              <button
                className={clsx("btn btn-sm", confirmClassName)}
                type="button"
                onClick={() => handleConfirm()}
              >
                {confirmText}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25" />
    </>
  );
}

const MemoisedModal = memo(Modal);

export default MemoisedModal;
