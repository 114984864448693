import { Auth } from "@supabase/ui";
import clsx from "clsx";
import { ReactElement, useState } from "react";
import Gravatar from "react-gravatar";

import { ProfileType } from "auth/actions";

import AlertSolid from "assets/icons/alert-solid";

interface AvatarProps {
  className?: string;
  size?: string;
  profile?: ProfileType | null;
}

function Avatar({
  className = "",
  size = "small",
  profile,
}: AvatarProps): ReactElement {
  const { session } = Auth.useUser();

  const [showFallback, setShowFallback] = useState(false);

  if (!session || !profile) {
    return <></>;
  }

  let av;
  if (
    !showFallback &&
    profile?.settings?.use_social_avatar &&
    session?.user?.user_metadata?.avatar_url
  ) {
    av = (
      <img
        src={session.user.user_metadata.avatar_url}
        alt={profile.display_name}
        className="object-cover object-center w-full h-full"
        onError={() => setShowFallback(true)}
      />
    );
  } else {
    av = (
      <Gravatar
        email={session?.user?.email}
        size={size === "large" ? 300 : 50}
      />
    );
  }

  let badge;
  if (!profile?.username) {
    badge = (
      <AlertSolid className="absolute -top-1.5 -right-1.5 w-4 h-4 text-red-500 bg-white rounded-full" />
    );
  }

  return (
    <div
      className={clsx(
        className,
        "relative",
        size === "large" ? "mx-auto w-32" : "w-8 h-8"
      )}
    >
      <div className="table overflow-hidden w-full h-full text-center bg-ink rounded-full shadow-inner cursor-pointer">
        {av}
        {size === "small" && badge}
      </div>
    </div>
  );
}

export default Avatar;
