import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "@supabase/ui";
import clsx from "clsx";
import _debounce from "lodash/debounce";
import _get from "lodash/get";
import _pick from "lodash/pick";
import {
  FocusEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm, useWatch, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query/types/react/types";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";

import { BookmarkViewType, LinkMetaType } from "bookmarks/actions";
import {
  useLinkMeta,
  useOwnBookmarkByShortId,
  useBookmarkCollections,
  useBookmarkDelete,
  useBookmarkUpsert,
} from "bookmarks/hooks";
import { CollectionType } from "collections/actions";
import { useCollectionCreate, useUserCollections } from "collections/hooks";
import { TagType } from "tags/actions";
import { useBookmarkTags, useTagCreate, useGlobalTags } from "tags/hooks";
import Error404 from "components/Error/404";
import Modal from "components/Modal";
import Preview from "components/Preview";
import Spinner from "components/Spinner";
import Tooltip from "components/Tooltip";
import { useApp } from "services/AppContext";
import { ServerError } from "utils/RequestError";
import { SelectOption } from "utils/types";
import { formatServerError } from "utils/helpers";
import { LocationStateType as BaseLocationStateType } from "utils/types";

import ChevronRightIcon from "assets/icons/chevron-right";
import LibraryIcon from "assets/icons/library";
import QuestionSolidIcon from "assets/icons/invisible";

type LocationStateType = {
  prefill?: BookmarkViewType;
} & BaseLocationStateType;

type BookmarkFormParams = {
  short_id?: string;
};

export type QueryOptions = {
  query: string;
  callback?: (options: SelectOption[]) => void;
};

export type FormValues = {
  id?: string;
  url: string;
  title: string;
  description: string;
  image_url: string;
  comment: string;
  is_private: boolean;
  collections: SelectOption[];
  tags: SelectOption[];
};

export const BookmarkSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  url: Yup.string().url().required(),
  title: Yup.string().nullable(),
  description: Yup.string().nullable(),
  image_url: Yup.string().nullable().label("display image url"),
  comment: Yup.string().nullable().max(500),
  is_private: Yup.bool().label("privacy setting"),
  collections: Yup.array().nullable(),
  tags: Yup.array().nullable(),
});

export const initialState = {
  id: "",
  url: "",
  title: "",
  description: "",
  image_url: "",
  comment: "",
  is_private: false,
  collections: [],
  tags: [],
};

export function optionSelector(
  data: TagType[] | CollectionType[]
): SelectOption[] {
  return data.map(
    (o) =>
      ({
        label: o.name,
        value: o.id,
      } as SelectOption)
  );
}

function BookmarkForm(): ReactElement {
  const { user } = Auth.useUser();
  const { bookmarkStore } = useApp();
  const history = useHistory();
  const { state } = useLocation<LocationStateType>();
  const { short_id } = useParams<BookmarkFormParams>();
  const { t } = useTranslation();

  const [queryOptions, setQueryOptions] = useState<QueryOptions>({
    query: "",
  });
  const [showModal, setShowModal] = useState(false);
  // https://github.com/JedWatson/react-select/discussions/4475#discussioncomment-439621
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: initialState,
    resolver: yupResolver(BookmarkSchema),
  });
  const watchURL = useWatch({ control, name: "url", defaultValue: "" });
  const watchTitle = useWatch({ control, name: "title", defaultValue: "" });
  const watchDescription = useWatch({
    control,
    name: "description",
    defaultValue: "",
  });
  const watchImageUrl = useWatch({
    control,
    name: "image_url",
    defaultValue: "",
  });
  const watchComment = useWatch({ control, name: "comment", defaultValue: "" });
  const watchCollections = useWatch({
    control,
    name: "collections",
    defaultValue: [],
  });
  const watchTags = useWatch({
    control,
    name: "tags",
    defaultValue: [],
  });

  const bookmark: UseQueryResult<BookmarkViewType> =
    useOwnBookmarkByShortId(short_id);
  const bookmarkCollections: UseQueryResult = useBookmarkCollections(
    bookmark?.data?.id,
    optionSelector
  );
  const userCollections: UseQueryResult = useUserCollections(
    user?.id as string,
    optionSelector
  );
  const bookmarkTags: UseQueryResult = useBookmarkTags(
    bookmark?.data?.id,
    optionSelector
  );
  const globalTags: UseQueryResult = useGlobalTags(
    queryOptions,
    optionSelector
  );
  const linkMeta: UseQueryResult<LinkMetaType> = useLinkMeta(watchURL);
  const deleteBookmark = useBookmarkDelete();
  const upsertBookmark = useBookmarkUpsert();
  const createCollection = useCollectionCreate();
  const createTag = useTagCreate();

  const handleCreateCollection = async (name: string) => {
    try {
      createCollection.mutate(
        {
          name,
        } as CollectionType,
        {
          onSuccess: (c) => {
            const values = getValues("collections");
            setValue("collections", [
              ...values,
              {
                label: (c as CollectionType).name,
                value: (c as CollectionType).id,
              } as SelectOption,
            ]);
          },
        }
      );
    } catch (e) {
      toast.error(t(formatServerError(e as ServerError)));
    }
  };

  const handleCreateTag = async (name: string) => {
    try {
      createTag.mutate(
        {
          name,
        } as TagType,
        {
          onSuccess: (c) => {
            const values = getValues("tags");
            setValue("tags", [
              ...values,
              {
                label: (c as TagType).name,
                value: (c as TagType).id,
              } as SelectOption,
            ]);
          },
        }
      );
    } catch (e) {
      toast.error(t(formatServerError(e as ServerError)));
    }
  };

  const handleDelete = async () => {
    if (!bookmark?.data?.id) {
      toast.error(t("Bookmark not found."));
      return;
    }
    deleteBookmark.mutate(bookmark.data.id, {
      onSuccess: async () => {
        bookmark?.data?.id &&
          (bookmarkStore as Map<number, BookmarkViewType>).delete(
            bookmark.data.id
          );
        history.push("/dashboard");
        toast.success(t("Deleted"));
      },
      onError: (e) => {
        toast.error(t(formatServerError(e as ServerError)));
      },
    });
  };

  const loadOptions = useCallback(
    (query = "", callback: (options: SelectOption[]) => void) => {
      if (query !== "" && callback) {
        setQueryOptions({ query, callback });
      }
    },
    []
  );

  const onUrlBlur = async (e: FocusEvent, onBlur: (e: FocusEvent) => void) => {
    const target = e.target as HTMLInputElement;
    target.value = target.value.split("?")[0];
    onBlur(e);
    const valid = await trigger("url");
    if (!short_id && valid) {
      await linkMeta.refetch();
    }
  };

  const onSubmit = async (
    input: BookmarkViewType & {
      collections: SelectOption[];
      tags: SelectOption[];
    }
  ) => {
    if (isMenuOpen) {
      return false;
    }

    upsertBookmark.mutate(
      {
        ...input,
        collections: input.collections.map((o) => o.value),
        tags: input.tags.map((o) => o.value),
      },
      {
        onSuccess: async (data) => {
          data &&
            (bookmarkStore as Map<number, BookmarkViewType>).set(
              Number((data as BookmarkViewType).id),
              data as BookmarkViewType
            );
          history.push(
            `/bookmarks/${(data as BookmarkViewType).short_id}/edit`
          );
          toast.success(t("Saved"));
        },
        onError: (e) => {
          toast.error(t(formatServerError(e as ServerError)));
        },
      }
    );
  };

  useEffect(() => {
    if (!linkMeta.isLoading && linkMeta.data) {
      setValue("title", _get(linkMeta.data, "title"));
      setValue("description", _get(linkMeta.data, "description"));
      setValue("image_url", _get(linkMeta?.data, "image"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkMeta.isLoading]);

  useEffect(() => {
    if (short_id) {
      if (!bookmark.isLoading && bookmark.data) {
        reset({
          ...initialState,
          ..._pick(bookmark?.data, Object.keys(initialState)),
          id: String(bookmark.data.id ?? ""),
        });
      }
    } else if (state?.prefill) {
      reset({
        ...initialState,
        ..._pick(state.prefill, Object.keys(initialState)),
        id: "",
      });
    } else {
      reset({ ...initialState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [short_id, bookmark.isLoading, bookmark.data, state?.prefill]);

  useEffect(() => {
    if (!bookmarkCollections.isLoading && bookmarkCollections.data) {
      setValue("collections", bookmarkCollections.data as SelectOption[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarkCollections.isLoading]);

  useEffect(() => {
    if (!bookmarkTags.isLoading && bookmarkTags.data) {
      setValue("tags", bookmarkTags.data as SelectOption[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarkTags.isLoading]);

  useEffect(() => {
    register("collections");
    register("tags");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (short_id && bookmark.isLoading) {
    return <Spinner fullScreen />;
  }

  if (short_id && !bookmark.isLoading && !bookmark.data) {
    return <Error404 className="my-10" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <nav aria-label="breadcrumb" className="text-sm">
        <ul className="inline-flex items-center space-x-2">
          <li className="inline-flex items-center">
            <LibraryIcon className="mr-2 w-4 h-4" />
            <Link to="/dashboard">My Trove</Link>
          </li>
          <li aria-current="page" className="inline-flex items-center">
            <ChevronRightIcon className="mr-2 w-4 h-4" />
            <span>{short_id ? t("Edit Bookmark") : t("Add Bookmark")}</span>
          </li>
        </ul>
      </nav>
      <h2 className="flex items-center mb-8">
        {short_id ? t("Edit Bookmark") : t("Add Bookmark")}
        {short_id && (
          <button className="hidden group-hover:inline-block ml-2 btn btn-xs btn-outline">
            {t("View")}
          </button>
        )}
      </h2>
      <h4>{t("Preview")}</h4>
      <Preview
        bookmark={{
          url: watchURL,
          title: watchTitle,
          description: watchDescription,
          image_url: watchImageUrl,
          tags: ((watchTags ?? []) as SelectOption[]).map((t) => t.label),
        }}
      />
      <div className="form-row">
        <div className="w-full form-group">
          <label htmlFor="url">{t("URL")}</label>
          <div className="relative">
            <Controller
              control={control}
              name="url"
              shouldUnregister={true}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <input
                  id={name}
                  type="url"
                  onBlur={(e) => onUrlBlur(e, onBlur)}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  readOnly={!!short_id}
                  className={clsx(errors.url && "border-red-500")}
                />
              )}
            />
            {linkMeta.isFetching && (
              <Spinner className="absolute top-1/3 right-2" size="sm" />
            )}
          </div>
          {errors.url ? (
            <p className="form-hint error">{errors.url.message}</p>
          ) : !short_id ? (
            <p className="form-hint">
              {t("URLs will be saved without query strings.")}
            </p>
          ) : null}
        </div>
      </div>
      <input id="title" type="hidden" {...register("title")} />
      <input id="description" type="hidden" {...register("description")} />
      <input id="image_url" type="hidden" {...register("image_url")} />
      <div className="form-row">
        <div className="w-full form-group">
          <label htmlFor="comment">{t("comment")}</label>
          <textarea
            id="comment"
            maxLength={500}
            {...register("comment")}
            className={clsx(errors.comment && "border-red-500")}
          />
          <div className="flex">
            {errors.comment && (
              <p className="form-hint error">{errors.comment.message}</p>
            )}
            <p
              className={clsx(
                "flex-1 text-right form-hint",
                watchComment.length / 500 > 0.9
                  ? "text-red-500"
                  : watchComment.length / 500 > 0.75
                  ? "text-yellow-400"
                  : null
              )}
            >
              {watchComment.length || 0}/500
            </p>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="w-full form-group">
          <input id="is_private" type="checkbox" {...register("is_private")} />
          <label htmlFor="is_private" className="inline-block ml-2">
            <span>{t("Private")}</span>
            <Tooltip text="Not visible to the public.">
              <QuestionSolidIcon className="inline-block w-3 h-3 align-baseline" />
            </Tooltip>
          </label>
        </div>
      </div>
      <div className="form-row">
        <div className="w-full form-group">
          <label htmlFor="tags">{t("Tags")}</label>
          <Controller
            control={control}
            name="tags"
            shouldUnregister={true}
            defaultValue={[]}
            render={({ field: { onChange } }) => (
              <AsyncCreatableSelect<SelectOption, boolean>
                className="react-select"
                classNamePrefix="react-select"
                isLoading={globalTags.isLoading}
                isMulti
                placeholder={t("Enter...")}
                onCreateOption={handleCreateTag}
                onChange={onChange}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                options={(globalTags.data ?? []) as SelectOption[]}
                loadOptions={_debounce(loadOptions, 500)}
                value={watchTags as SelectOption[]}
              />
            )}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="w-full form-group">
          <label htmlFor="collections">{t("Collections")}</label>
          <Controller
            control={control}
            name="collections"
            shouldUnregister={true}
            defaultValue={[]}
            render={({ field: { onChange } }) => (
              <CreatableSelect<SelectOption, boolean>
                className="react-select"
                classNamePrefix="react-select"
                isClearable
                isMulti
                isDisabled={userCollections.isLoading || !userCollections.data}
                isLoading={userCollections.isLoading}
                onCreateOption={handleCreateCollection}
                onChange={onChange}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                options={(userCollections.data ?? []) as SelectOption[]}
                value={watchCollections as SelectOption[]}
              />
            )}
          />
          <p className="form-hint">
            {t("New collections will be private by default.")}
          </p>
        </div>
      </div>
      <input id="id" type="hidden" {...register("id")} />
      <div className="mt-2 space-x-2">
        <button
          type="submit"
          className="btn btn-sm btn-solid btn-ink"
          disabled={isSubmitting || upsertBookmark.isLoading}
        >
          <span>{t("Save")}</span>
          <span
            className={clsx(
              "ml-2 w-4 h-4 border-2 loader inverse",
              !isSubmitting && !upsertBookmark.isLoading && "hidden"
            )}
          />
        </button>
        {short_id && (
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="btn btn-sm btn-solid btn-danger"
          >
            <span>{t("Delete")}</span>
            <span
              className={clsx(
                "ml-2 w-4 h-4 border-2 loader inverse",
                !deleteBookmark.isLoading && "hidden"
              )}
            />
          </button>
        )}
        <button
          type="button"
          onClick={() => history.push("/dashboard")}
          className="btn btn-sm"
        >
          <span>{t("Back")}</span>
        </button>
      </div>
      {showModal && (
        <Modal
          title={t("Delete bookmark")}
          onCancel={() => setShowModal(false)}
          onConfirm={() => handleDelete()}
          confirmText={t("Delete")}
          confirmClassName="bg-red-500 text-white"
          modalClassName="w-full h-full md:w-96 md:h-auto"
        >
          <p>{t("Are you sure?")}</p>
        </Modal>
      )}
    </form>
  );
}

export default BookmarkForm;
