import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Logo from "assets/svg/logo.svg";
import clsx from "clsx";

const { REACT_APP_SITE_NAME } = process.env;

function Brand({ isExt = false }: { isExt?: boolean }): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Link className="self-center mr-3" style={{ lineHeight: 1 }} to="/">
        <img
          src={Logo}
          alt={t("Thesaurus Inventus Logo")}
          className="w-8 md:w-10 h-8 md:h-10"
        />
      </Link>
      <div
        className={clsx(
          "whitespace-nowrap",
          !isExt ? "overflow-hidden invisible md:visible" : undefined
        )}
      >
        <Link
          className="font-playfair text-sm sm:text-lg md:text-2xl font-bold text-gray-800"
          style={{ lineHeight: 1 }}
          to="/"
        >
          {REACT_APP_SITE_NAME}
        </Link>
        <span className="block mt-0.5 text-xs">
          by <a href="https://tourbillonlabs.com">Tourbillon Labs</a>
        </span>
      </div>
    </>
  );
}

export default Brand;
