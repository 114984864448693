import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useChangePassword } from "auth/hooks";
import { ServerError } from "utils/RequestError";
import { formatServerError } from "utils/helpers";

type FormValues = {
  password: string;
  confirmPassword: string;
};

const PasswordSchema = Yup.object().shape({
  password: Yup.string().min(8).required(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

function ChangePasswordForm(): ReactElement {
  const { t } = useTranslation();
  const changePassword = useChangePassword();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormValues>({
    mode: "onBlur",
    resolver: yupResolver(PasswordSchema),
  });

  const onSubmit = async (input: FormValues) => {
    changePassword.mutate(input.password, {
      onSuccess: () => {
        toast.success(t("Saved."), {
          position: "bottom-left",
          duration: 4000,
        });
      },
      onError: (e) => {
        toast.error(t(formatServerError(e as ServerError)));
      },
    });
  };

  return (
    <form
      id="password-form"
      className="p-3 bg-whitesmoke card"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-row">
        <div className="w-full form-group">
          <label htmlFor="password">{t("New Password")}</label>
          <input id="password" type="password" {...register("password")} />
          {errors?.password?.message && (
            <p className="form-hint error">{errors.password.message}</p>
          )}
        </div>
      </div>
      <div className="last:mb-0 form-row">
        <div className="w-full form-group">
          <label htmlFor="confirmPassword">{t("Confirm Password")}</label>
          <div className="flex gap-2 mb-3">
            <input
              id="confirmPassword"
              type="password"
              {...register("confirmPassword")}
              className="mb-0"
            />
            <button
              type="submit"
              form="password-form"
              disabled={!isDirty || isSubmitting || changePassword.isLoading}
              className="flex-shrink-0 leading-none btn btn-sm btn-solid btn-ink"
            >
              <span>{t("Change password")}</span>
              <span
                className={clsx(
                  "ml-2 w-4 h-4 border-2 loader inverse",
                  !isSubmitting && !changePassword.isLoading && "hidden"
                )}
              />
            </button>
          </div>
          {errors?.confirmPassword?.message && (
            <p className="form-hint error">{errors.confirmPassword.message}</p>
          )}
        </div>
      </div>
    </form>
  );
}

export default ChangePasswordForm;
