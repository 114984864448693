import { memo, ReactElement, ReactNode } from "react";
import _isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";

function Tooltip({
  children,
  text,
}: {
  children: ReactNode;
  text: string;
}): ReactElement {
  const { t } = useTranslation();
  return (
    <span className="group inline-block relative">
      {children}
      <span className="hidden group-hover:block absolute -top-9 left-0 p-2 text-white normal-case whitespace-nowrap bg-black rounded-md opacity-70">
        {t(text)}
      </span>
    </span>
  );
}

const MemoisedTooltip = memo(Tooltip, (prevProps, nextProps) =>
  _isEqual(prevProps.text, nextProps.text)
);

export default MemoisedTooltip;
