import { ReactElement } from "react";

function Kofi(): ReactElement {
  return (
    <iframe
      id="kofiframe"
      className="px-4 pt-8 pb-0 mx-auto w-full bg-gray-100 border-0"
      src="https://ko-fi.com/tourbillonlabs/?hidefeed=true&widget=true&embed=true&preview=true"
      height="712"
      title="tourbillonlabs"
    />
  );
}

export default Kofi;
