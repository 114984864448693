import React, { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { UseQueryResult } from "react-query/types/react/types";

import { ProfileType } from "auth/actions";
import { useUserProfileByUsername } from "auth/hooks";
import { BookmarksSuccessCallback } from "bookmarks/actions";
import { useUserBookmarks } from "bookmarks/hooks";
import { CollectionType } from "collections/actions";
import { useUserCollections } from "collections/hooks";
import BookmarkGrid, { InfiniteBookmarksResult } from "components/BookmarkGrid";
import CollectionList from "components/CollectionList";
import Error404 from "components/Error/404";
import Spinner from "components/Spinner";
import { useApp } from "services/AppContext";
import ProfileSummary from "auth/components/ProfileSummary";

type ProfileParams = {
  username: string;
};

function Profile(): ReactElement {
  const { username } = useParams<ProfileParams>();
  const { updateBookmarkStore } = useApp();

  const profile: UseQueryResult<ProfileType> =
    useUserProfileByUsername(username);
  const collections = useUserCollections(profile?.data?.id as string);
  const bookmarks = useUserBookmarks(
    profile?.data?.id as string,
    updateBookmarkStore as BookmarksSuccessCallback
  );

  if (profile.isLoading || collections.isLoading || bookmarks.isLoading) {
    return <Spinner className="mx-auto mt-10" size="lg" />;
  }

  if (!profile.isLoading && !profile.data) {
    return <Error404 className="my-10" />;
  }

  const title = profile.data.display_name
    ? `${profile.data.display_name} (@${profile.data.username})`
    : `@${profile.data.username}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={profile.data.bio} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={profile.data.bio} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={profile.data.bio} />
      </Helmet>
      <div className="grid grid-cols-4 p-6 sm:px-6 lg:px-16 space-y-8 md:space-y-0 space-x-4">
        <div className="col-span-full md:col-span-1 text-center">
          {!profile.isLoading && profile.data && (
            <ProfileSummary profile={profile.data} />
          )}
          {!collections.isLoading && collections.data && (
            <>
              <hr className="my-6" />
              <CollectionList
                collections={collections.data as CollectionType[]}
              />
            </>
          )}
        </div>
        <div className="col-span-full md:col-span-3">
          <BookmarkGrid bookmarks={bookmarks as InfiniteBookmarksResult} />
        </div>
      </div>
    </>
  );
}

export default Profile;
