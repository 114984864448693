import clsx from "clsx";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import DonutLove from "assets/svg/donut_love.svg";

type Error404Props = {
  className?: string;
};

function Error404({ className }: Error404Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "flex flex-col justify-center items-center px-4 w-full text-center",
        className
      )}
    >
      <img
        src={DonutLove}
        alt={t("Not Found Image")}
        className="w-full md:w-1/3"
      />
      <h1 className="my-8">{t("404: Oops! You're lost.")}</h1>
      <Link to="/" className="btn btn-sm btn-solid btn-ink">
        {t("Go Home")}
      </Link>
    </div>
  );
}

export default Error404;
