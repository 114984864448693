import { ComponentType, ReactElement, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Route, RouteProps, useLocation } from "react-router-dom";

import Fab from "components/Fab";
import Header from "./components/Header";
import Seo from "./components/Seo";

type DefaultLayoutProps = {
  component: ComponentType;
  showFab?: boolean;
} & Record<string, unknown>;

type DefaultRouteProps = RouteProps & {
  component: ComponentType;
  showFab?: boolean;
} & Record<string, unknown>;

export function DefaultLayout({
  component: Component,
  showFab = false,
  ...rest
}: DefaultLayoutProps): ReactElement {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const [key, val] = location.hash.substring(1).split("=");
      if (key === "message") {
        window.history.replaceState(
          "",
          document.title,
          location.pathname + location.search
        );
        toast.success(val.replace(/\+/g, " "), {
          position: "bottom-left",
          duration: 4000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Seo />
      {!rest.isExt && <Header />}
      <main role="main" className="flex-1">
        <Component {...rest} />
        {showFab && <Fab />}
        <Toaster position="bottom-left" />
      </main>
    </>
  );
}

function DefaultRoute({
  component,
  showFab = false,
  isExt = false,
  ...rest
}: DefaultRouteProps): ReactElement {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout
          component={component}
          showFab={showFab}
          isExt={isExt}
          {...props}
        />
      )}
    />
  );
}

export default DefaultRoute;
