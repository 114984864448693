import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "@supabase/ui";
import clsx from "clsx";
import _debounce from "lodash/debounce";
import _pick from "lodash/pick";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useForm, useWatch, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query/types/react/types";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";

import { BookmarkViewType } from "bookmarks/actions";
import { useBookmarkUpsert } from "bookmarks/hooks";
import { CollectionType } from "collections/actions";
import { useCollectionCreate, useUserCollections } from "collections/hooks";
import { TagType } from "tags/actions";
import { useTagCreate, useGlobalTags } from "tags/hooks";
import ExtensionHelper from "components/ExtensionHelper";
import Preview from "components/Preview";
import Tooltip from "components/Tooltip";
import { useApp } from "services/AppContext";
import { ServerError } from "utils/RequestError";
import { SelectOption } from "utils/types";
import { formatServerError } from "utils/helpers";

import QuestionSolidIcon from "assets/icons/invisible";
import {
  QueryOptions,
  FormValues,
  BookmarkSchema,
  initialState,
  optionSelector,
} from "./Form";

const { REACT_APP_CHROME_EXTENSION } = process.env;

function BookmarkForm(): ReactElement {
  const { session, user } = Auth.useUser();
  const { bookmarkStore } = useApp();
  const { t } = useTranslation();
  const [pending, setPending] = useState<{
    url?: string;
    title?: string;
    description?: string;
    image_url?: string;
  }>({});

  const [queryOptions, setQueryOptions] = useState<QueryOptions>({
    query: "",
  });
  // https://github.com/JedWatson/react-select/discussions/4475#discussioncomment-439621
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);

  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: initialState,
    resolver: yupResolver(BookmarkSchema),
  });
  const watchComment = useWatch({ control, name: "comment", defaultValue: "" });
  const watchCollections = useWatch({
    control,
    name: "collections",
    defaultValue: [],
  });
  const watchTags = useWatch({
    control,
    name: "tags",
    defaultValue: [],
  });

  const userCollections: UseQueryResult = useUserCollections(
    user?.id as string,
    optionSelector
  );
  const globalTags: UseQueryResult = useGlobalTags(
    queryOptions,
    optionSelector
  );
  const upsertBookmark = useBookmarkUpsert();
  const createCollection = useCollectionCreate();
  const createTag = useTagCreate();

  const handleCreateCollection = async (name: string) => {
    try {
      createCollection.mutate(
        {
          name,
        } as CollectionType,
        {
          onSuccess: (c) => {
            const values = getValues("collections");
            setValue("collections", [
              ...values,
              {
                label: (c as CollectionType).name,
                value: (c as CollectionType).id,
              } as SelectOption,
            ]);
          },
        }
      );
    } catch (e) {
      toast.error(t(formatServerError(e as ServerError)));
    }
  };

  const handleCreateTag = async (name: string) => {
    try {
      createTag.mutate(
        {
          name,
        } as TagType,
        {
          onSuccess: (c) => {
            const values = getValues("tags");
            setValue("tags", [
              ...values,
              {
                label: (c as TagType).name,
                value: (c as TagType).id,
              } as SelectOption,
            ]);
          },
        }
      );
    } catch (e) {
      toast.error(t(formatServerError(e as ServerError)));
    }
  };

  const loadOptions = useCallback(
    (query = "", callback: (options: SelectOption[]) => void) => {
      if (query !== "" && callback) {
        setQueryOptions({ query, callback });
      }
    },
    []
  );

  const onSubmit = async (
    input: BookmarkViewType & {
      collections: SelectOption[];
      tags: SelectOption[];
    }
  ) => {
    if (isMenuOpen) {
      return false;
    }

    upsertBookmark.mutate(
      {
        ...input,
        collections: input.collections.map((o) => o.value),
        tags: input.tags.map((o) => o.value),
      },
      {
        onSuccess: async (data) => {
          data &&
            (bookmarkStore as Map<number, BookmarkViewType>).set(
              Number((data as BookmarkViewType).id),
              data as BookmarkViewType
            );
          setHasSaved(true);
        },
        onError: (e) => {
          toast.error(t(formatServerError(e as ServerError)));
        },
      }
    );
  };

  useEffect(() => {
    register("collections");
    register("tags");

    const initialiseForm = async () => {
      chrome &&
        chrome.runtime.sendMessage(
          REACT_APP_CHROME_EXTENSION as string,
          { message: "fetch_bookmark" },
          (res) => {
            if (res) {
              setPending(res);
              reset({
                ...initialState,
                ..._pick(res, Object.keys(initialState)),
                id: "",
              });
            } else {
              reset({ ...initialState });
            }
          }
        );
    };
    initialiseForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!session || hasSaved) {
    return <ExtensionHelper />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-0 mb-4">
      <h2 className="flex items-center mb-8">{t("Add Bookmark")}</h2>
      <input id="url" type="hidden" {...register("url")} />
      <input id="title" type="hidden" {...register("title")} />
      <input id="description" type="hidden" {...register("description")} />
      <input id="image_url" type="hidden" {...register("image_url")} />
      <h4>{t("Preview")}</h4>
      <Preview
        bookmark={{
          url: pending?.url as string,
          title: pending?.title as string,
          description: pending?.description as string,
          image_url: pending?.image_url as string,
          tags: ((watchTags ?? []) as SelectOption[]).map((t) => t.label),
        }}
      />
      <div className="form-row">
        <div className="w-full form-group">
          <label htmlFor="comment">{t("comment")}</label>
          <textarea
            id="comment"
            maxLength={500}
            {...register("comment")}
            className={clsx(errors.comment && "border-red-500")}
          />
          <div className="flex">
            {errors.comment && (
              <p className="form-hint error">{errors.comment.message}</p>
            )}
            <p
              className={clsx(
                "flex-1 text-right form-hint",
                watchComment.length / 500 > 0.9
                  ? "text-red-500"
                  : watchComment.length / 500 > 0.75
                  ? "text-yellow-400"
                  : null
              )}
            >
              {watchComment.length || 0}/500
            </p>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="w-full form-group">
          <input id="is_private" type="checkbox" {...register("is_private")} />
          <label htmlFor="is_private" className="inline-block ml-2">
            <span>{t("Private")}</span>
            <Tooltip text="Not visible to the public.">
              <QuestionSolidIcon className="inline-block w-3 h-3 align-baseline" />
            </Tooltip>
          </label>
        </div>
      </div>
      <div className="form-row">
        <div className="w-full form-group">
          <label htmlFor="tags">{t("Tags")}</label>
          <Controller
            control={control}
            name="tags"
            shouldUnregister={true}
            defaultValue={[]}
            render={({ field: { onChange } }) => (
              <AsyncCreatableSelect<SelectOption, boolean>
                className="react-select"
                classNamePrefix="react-select"
                isLoading={globalTags.isLoading}
                isMulti
                placeholder={t("Enter...")}
                onCreateOption={handleCreateTag}
                onChange={onChange}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                options={(globalTags.data ?? []) as SelectOption[]}
                loadOptions={_debounce(loadOptions, 500)}
                value={watchTags as SelectOption[]}
              />
            )}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="w-full form-group">
          <label htmlFor="collections">{t("Collections")}</label>
          <Controller
            control={control}
            name="collections"
            shouldUnregister={true}
            defaultValue={[]}
            render={({ field: { onChange } }) => (
              <CreatableSelect<SelectOption, boolean>
                className="react-select"
                classNamePrefix="react-select"
                isClearable
                isMulti
                isDisabled={userCollections.isLoading || !userCollections.data}
                isLoading={userCollections.isLoading}
                onCreateOption={handleCreateCollection}
                onChange={onChange}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                options={(userCollections.data ?? []) as SelectOption[]}
                value={watchCollections as SelectOption[]}
              />
            )}
          />
          <p className="form-hint">
            {t("New collections will be private by default.")}
          </p>
        </div>
      </div>
      <input id="id" type="hidden" {...register("id")} />
      <div className="mt-2 space-x-2">
        <button
          type="submit"
          className="btn btn-sm btn-solid btn-ink"
          disabled={isSubmitting || upsertBookmark.isLoading}
        >
          <span>{t("Save")}</span>
          <span
            className={clsx(
              "ml-2 w-4 h-4 border-2 loader inverse",
              !isSubmitting && !upsertBookmark.isLoading && "hidden"
            )}
          />
        </button>
      </div>
    </form>
  );
}

export default BookmarkForm;
