import { User } from "@supabase/supabase-js";
import _pick from "lodash/pick";

import supabase from "services/supabase";
import { ServerError } from "utils/RequestError";

export interface ProfileType {
  id: string;
  username?: string;
  display_name?: string;
  bio?: string;
  website?: string;
  email?: string;
  settings?: {
    use_social_avatar?: boolean;
  };
  subscribed?: boolean;
  updated_at?: string;
}

const VALID_PROFILE_KEYS = [
  "username",
  "display_name",
  "bio",
  "website",
  "settings",
];

export const profileKeys = {
  all: ["profiles"] as const,
  lists: () => [...profileKeys.all, "list"] as const,
  list: (filters: string) => [...profileKeys.lists(), { filters }] as const,
  details: () => [...profileKeys.all, "detail"] as const,
  detail: (id: string) => [...profileKeys.details(), id] as const,
};

async function changeEmail(email: string): Promise<User | null> {
  const { data, error } = await supabase.auth.update({ email });
  if (error) {
    throw new ServerError({ status: 400, message: error?.message });
  } else {
    return data ?? null;
  }
}

async function changePassword(password: string): Promise<User | null> {
  const { data, error } = await supabase.auth.update({ password });
  if (error) {
    throw new ServerError({ status: 400, message: error?.message });
  } else {
    return data ?? null;
  }
}

const getOwnProfile = async (): Promise<ProfileType | undefined> => {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const { data, error, status } = await supabase
    .from("profiles")
    .select("*")
    .eq("id", supabase?.auth?.user()?.id);
  if (error) {
    throw new ServerError({ status, code: error.code });
  }
  if (!data || !data[0]) {
    return undefined;
  } else {
    return data[0];
  }
};

async function getUserProfile(id?: string): Promise<ProfileType | undefined> {
  if (!id) {
    throw new ServerError({ status: 400, message: "User ID is required." });
  }
  const { data, error, status } = await supabase
    .from("profiles")
    .select("*")
    .eq("id", id);
  if (error) {
    throw new ServerError({ status, code: error.code });
  }
  if (!data || !data[0]) {
    return undefined;
  } else {
    return data[0];
  }
}

async function getUserProfileByUsername(
  username?: string
): Promise<ProfileType | undefined> {
  if (!username) {
    throw new ServerError({ status: 400, message: "Username is required." });
  }
  const { data, error, status } = await supabase
    .from("profiles")
    .select("*")
    .eq("username", username);
  if (error) {
    throw new ServerError({ status, code: error.code });
  }
  if (!data || !data[0]) {
    return undefined;
  } else {
    return data[0];
  }
}

async function updateProfile(
  changes: ProfileType
): Promise<ProfileType | undefined> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const id = supabase?.auth?.user()?.id;
  const { data, error, status } = await supabase
    .from("profiles")
    .update(_pick(changes, VALID_PROFILE_KEYS))
    .match({ id });
  if (error) {
    throw new ServerError({ status, code: error.code });
  }
  if (!data || !data[0]) {
    return undefined;
  } else {
    return data[0];
  }
}

const auth = {
  changeEmail,
  changePassword,
  getOwnProfile,
  getUserProfile,
  getUserProfileByUsername,
  updateProfile,
};

export default auth;
