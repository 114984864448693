interface ServerErrorProps {
  status?: string | number;
  code?: string | number;
  message: string;
}

export class ServerError extends Error {
  public code?: string | number;
  public status?: string | number;

  constructor(
    { status, code, message }: Partial<ServerErrorProps>,
    ...params: never[]
  ) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }

    this.name = "ServerError";
    this.status = status;
    this.code = code;
    if (typeof message === "string") {
      this.message = message;
    }
  }
}
