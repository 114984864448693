import React, { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query/types/react/types";
import { useHistory, useParams, Link } from "react-router-dom";

import { useCollectionBySlug, useCollectionBookmarks } from "collections/hooks";
import { CollectionType } from "collections/actions";
import Error404 from "components/Error/404";
import Spinner from "components/Spinner";
import BookmarkGrid, { InfiniteBookmarksResult } from "components/BookmarkGrid";
import { UpdateBookmarkStoreCallbackType, useApp } from "services/AppContext";

type CollectionViewParams = {
  slug: string;
  username?: string;
};

function CollectionView(): ReactElement {
  const { updateBookmarkStore } = useApp();
  const history = useHistory();
  const { t } = useTranslation();
  const { username, slug } = useParams<CollectionViewParams>();

  const collection: UseQueryResult<CollectionType> = useCollectionBySlug(
    slug,
    username
  );
  const bookmarks = useCollectionBookmarks(
    collection?.data as CollectionType,
    updateBookmarkStore as UpdateBookmarkStoreCallbackType
  );

  const handleClick = () => {
    if (!collection.data) {
      toast.error(t("Collection does not exist."));
    }
    history.push(`/collections/${collection?.data?.slug}/edit`);
  };

  if (collection.isLoading) {
    return <Spinner className="mx-auto mt-10" size="lg" />;
  }

  if (!collection.isLoading && !collection.data) {
    return <Error404 className="my-10" />;
  }

  const title = `@${username} / ${collection.data.name}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <div className="p-4 sm:px-6 lg:px-16">
        <h2 className="group">
          {username && (
            <>
              <Link to={`@${username}`}>{`@${username}`}</Link>
              {" / "}
            </>
          )}
          {collection?.data?.name}{" "}
          <button
            className="hidden group-hover:inline-block mb-1 ml-2 align-text-bottom btn btn-xs btn-outline"
            onClick={handleClick}
          >
            {t("Edit")}
          </button>
        </h2>
        {bookmarks.isLoading ? (
          <Spinner className="mx-auto" size="md" />
        ) : (
          !bookmarks.error && (
            <BookmarkGrid
              bookmarks={bookmarks as InfiniteBookmarksResult}
              className="md:grid-cols-3 lg:grid-cols-4 my-6"
              emptyStyle="sparkle"
            />
          )
        )}
      </div>
    </>
  );
}

export default CollectionView;
