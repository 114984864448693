import { useCallback } from "react";
import {
  MutationFunction,
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  UseInfiniteQueryResult,
  UseQueryResult,
} from "react-query/types/react/types";

import { BookmarkViewType, PagedBookmarksType } from "bookmarks/actions";
import { CollectionType } from "collections/actions";
import tagActions, { TagType } from "tags/actions";
import { UpdateBookmarkStoreCallbackType } from "services/AppContext";
import { SelectOption } from "utils/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
export function useTagBySlug<T extends any = TagType>(
  slug: string
): UseQueryResult<TagType> {
  return useQuery(`tag-${slug}`, () => tagActions.getTagBySlug(slug), {
    enabled: !!slug && slug.length > 0,
  });
}

export function useBookmarkTags<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends any = TagType[] | number[]
>(id?: number, select?: (data: TagType[]) => T): UseQueryResult {
  return useQuery(
    `bookmark_tags-${id}`,
    () => tagActions.getBookmarkTags(id as number),
    {
      enabled: !!id,
      select,
    }
  );
}

export function useTagBookmarks(
  tag: TagType,
  updateBookmarkStore: UpdateBookmarkStoreCallbackType
): UseInfiniteQueryResult<BookmarkViewType[]> {
  return useInfiniteQuery(
    `tag-${tag?.id}-bookmarks`,
    ({ pageParam = 1 }) => tagActions.getTagBookmarks(tag),
    {
      getNextPageParam: (data: PagedBookmarksType) => data.nextPage ?? false,
      onSuccess: useCallback((data) => {
        updateBookmarkStore(data.pages[data.pages.length - 1].results);
        return data;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []),
      enabled: !!tag && !!tag.id,
    }
  );
}

export function useGlobalTags<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends any = TagType[] | number[]
>(
  tagOptions: { query: string; callback?: (options: SelectOption[]) => void },
  select?: (data: TagType[]) => T
): UseQueryResult {
  return useQuery(
    ["tags", { q: tagOptions.query }],
    () => tagActions.getGlobalTags(tagOptions.query),
    {
      enabled: tagOptions.query !== "",
      select: (data) => {
        select &&
          tagOptions.callback &&
          tagOptions.callback(select(data) as SelectOption[]);
      },
    }
  );
}

export function usePopularTags(): UseQueryResult<TagType[]> {
  return useQuery("popular_tags", tagActions.getPopularTags);
}

export function useTagCreate(): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(tagActions.insertTag as MutationFunction<CollectionType>, {
    onSuccess: () => {
      queryClient.invalidateQueries("tags");
    },
  });
}
