import { useCallback } from "react";
import {
  MutationFunction,
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  UseInfiniteQueryResult,
  UseQueryResult,
} from "react-query/types/react/types";

import { BookmarkViewType, PagedBookmarksType } from "bookmarks/actions";
import collectionActions, {
  CollectionType,
  collectionKeys,
} from "collections/actions";
import { UpdateBookmarkStoreCallbackType } from "services/AppContext";
import supabase from "services/supabase";
import { SelectOption } from "utils/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
export function useCollectionBySlug<T extends any = CollectionType>(
  slug: string,
  username?: string
): UseQueryResult<CollectionType> {
  return useQuery(
    collectionKeys.detail(username ?? "unknown", slug),
    () => collectionActions.getCollectionBySlug(slug, username),
    {
      enabled: !!slug && slug.length > 0,
    }
  );
}

export function useOwnCollectionBySlug(
  slug: string
): UseQueryResult<CollectionType> {
  return useQuery(
    collectionKeys.detail(supabase?.auth?.user()?.id as string, slug),
    () => collectionActions.getCollectionBySlug(slug),
    {
      enabled: !!slug && slug.length > 0,
    }
  );
}

// https://github.com/tannerlinsley/react-query/discussions/1195#discussioncomment-110896
export function useUserCollections<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends any = CollectionType[] | SelectOption[]
>(id: string, select?: (data: CollectionType[]) => T): UseQueryResult {
  return useQuery(
    collectionKeys.list(id),
    () => collectionActions.getUserCollections(id),
    {
      enabled: !!id,
      select,
    }
  );
}

export function useCollectionBookmarks(
  collection: CollectionType,
  updateBookmarkStore: UpdateBookmarkStoreCallbackType
): UseInfiniteQueryResult<BookmarkViewType[]> {
  return useInfiniteQuery(
    collectionKeys.detail(
      (collection?.user_id ?? "unknown") as string,
      (collection?.slug ?? "unknown") as string,
      { bookmarks: true }
    ),
    ({ pageParam = 1 }) =>
      collectionActions.getCollectionBookmarks(collection, pageParam),
    {
      getNextPageParam: (data: PagedBookmarksType) => data.nextPage ?? false,
      onSuccess: useCallback((data) => {
        updateBookmarkStore(data.pages[data.pages.length - 1].results);
        return data;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []),
      enabled: !!collection && !!collection.id,
    }
  );
}

export function useCollectionCreate(): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(
    collectionActions.insertCollection as MutationFunction<CollectionType>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          collectionKeys.list(supabase?.auth?.user()?.id as string)
        );
      },
    }
  );
}

export function useCollectionUpsert(): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(
    collectionActions.upsertCollection as MutationFunction<CollectionType>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          collectionKeys.list(supabase?.auth?.user()?.id as string)
        );
      },
    }
  );
}

export function useCollectionDelete(): UseMutationResult {
  const queryClient = useQueryClient();

  return useMutation(
    collectionActions.deleteCollection as MutationFunction<void>,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          collectionKeys.list(supabase?.auth?.user()?.id as string)
        );
      },
    }
  );
}
