import axios from "axios";
import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";

import collectionActions, { CollectionType } from "collections/actions";
import tagActions from "tags/actions";
import supabase from "services/supabase";
import { ServerError } from "utils/RequestError";
import { getPagination } from "utils/helpers";
import { PAGE_SIZE } from "../constants";

export type LinkType = {
  id?: number;
  url: string;
  title: string;
  description: string;
  image_url: string;
  created_at?: string;
};

export type LinkMetaType = {
  title: string;
  description: string;
  image: string;
};

export type BookmarkType = LinkType & {
  id?: number;
  link_id?: number;
  short_id?: string;
  user_id?: string;
  comment?: string;
  is_private?: boolean;
  created_at?: string;
  updated_at?: string;
};

export type BookmarkViewType = BookmarkType & {
  hits?: number;
};

export type PagedBookmarksType = {
  results: BookmarkViewType[];
  nextPage?: number;
};

export type BookmarksSuccessCallback = (bookmarks: BookmarkViewType[]) => void;

const { REACT_APP_SERVICE_API_KEY, REACT_APP_SERVICE_URL } = process.env;

const VALID_EDITABLE_BOOKMARK_KEYS = ["comment", "is_private"];
export const VALID_EDITABLE_LINK_KEYS = ["title", "description", "image_url"];

export const linkKeys = {
  all: ["links"] as const,
  lists: () => [...linkKeys.all, "list"] as const,
  list: (filters: string | number) =>
    [...linkKeys.lists(), { filters }] as const,
  details: () => [...linkKeys.all, "detail"] as const,
  detail: (id: string | number) => [...linkKeys.details(), id] as const,
};

export const bookmarkKeys = {
  all: ["bookmarks"] as const,
  lists: () => [...bookmarkKeys.all, "list"] as const,
  list: (key: string, filters?: string) =>
    [...bookmarkKeys.lists(), { key, filters }] as const,
  details: () => [...bookmarkKeys.all, "detail"] as const,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  detail: (
    id: string | number,
    options?: { own?: boolean; collections?: boolean }
  ) =>
    options?.own
      ? ([...bookmarkKeys.details(), "own", id] as const)
      : options?.collections
      ? ([...bookmarkKeys.details(), "collections", id] as const)
      : ([...bookmarkKeys.details(), id] as const),
};

async function insertLink(link: LinkType): Promise<LinkType> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const { data, error, status } = await supabase
    .from("links")
    .insert(_pick(link, ["url", ...VALID_EDITABLE_LINK_KEYS]));
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

async function updateLink(id: number, input: LinkType): Promise<LinkType> {
  const { data, error, status } = await supabase
    .from("links")
    .update(_pick(input, VALID_EDITABLE_LINK_KEYS))
    .match({ id });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

async function upsertLink(input: LinkType): Promise<LinkType> {
  if (!input.url) {
    throw new ServerError({ status: 400, message: "URL is required." });
  }
  let link = await getLinkByUrl(input?.url);
  if (link) {
    if (
      !_isEqual(
        _pick(input, VALID_EDITABLE_LINK_KEYS),
        _pick(link, VALID_EDITABLE_LINK_KEYS)
      )
    ) {
      link = await updateLink(
        Number(link?.id),
        _pick(input, VALID_EDITABLE_LINK_KEYS) as LinkType
      );
    }
  } else {
    link = await insertLink(
      _pick(input, ["url", ...VALID_EDITABLE_LINK_KEYS]) as LinkType
    );
  }

  if (!link) {
    throw new ServerError({ status: 500, message: "Error adding link." });
  }

  return link;
}

async function getLinkByUrl(url: string): Promise<LinkType | undefined> {
  const { data, error, status } = await supabase
    .from("links")
    .select("*")
    .match({ url });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

async function fetchLinkMeta(link: string): Promise<LinkMetaType> {
  const url = new URL(link);
  url.search = "";

  const { data, status, statusText } = await axios.get(
    `${REACT_APP_SERVICE_URL}/og/${encodeURIComponent(url.href)}`,
    {
      headers: {
        "X-API-Key": REACT_APP_SERVICE_API_KEY as string,
      },
    }
  );
  if (status !== 200) {
    throw new ServerError({ status, message: statusText });
  } else {
    return data;
  }
}

async function insertBookmark(input: BookmarkType): Promise<BookmarkType> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const { data, error, status } = await supabase
    .from("bookmarks")
    .insert(_pick(input, ["link_id", ...VALID_EDITABLE_BOOKMARK_KEYS]));
  if (error) {
    switch (error.code) {
      case "23505":
        throw new ServerError({
          status,
          code: error.code,
          message: `You have already bookmarked this URL.`,
        });
      default:
        throw new ServerError({ status, code: error.code });
    }
  } else {
    return data ? data[0] : undefined;
  }
}

async function updateBookmark(
  id: number,
  input: BookmarkType
): Promise<BookmarkType> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const { data, error, status } = await supabase
    .from("bookmarks")
    .update(_pick(input, VALID_EDITABLE_BOOKMARK_KEYS))
    .match({ id, user_id: supabase?.auth?.user()?.id });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

async function upsertBookmark(
  input: BookmarkViewType & { collections: number[]; tags: number[] }
): Promise<BookmarkViewType> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const link = await upsertLink(input as LinkType);
  let bookmark;
  if (!input.id) {
    bookmark = await insertBookmark({
      link_id: link.id,
      ..._pick(input, VALID_EDITABLE_BOOKMARK_KEYS),
    } as BookmarkType);
  } else {
    bookmark = await updateBookmark(
      input.id,
      _pick(input, VALID_EDITABLE_BOOKMARK_KEYS) as BookmarkType
    );
  }
  await collectionActions.upsertBookmarkCollections(
    bookmark,
    input.collections
  );
  await tagActions.upsertBookmarkTags(bookmark, input.tags);

  return {
    ...bookmark,
    ..._pick(link, ["url", ...VALID_EDITABLE_LINK_KEYS]),
  } as BookmarkViewType;
}

async function deleteBookmark(id?: number): Promise<void> {
  if (!id) {
    throw new ServerError({ status: 400, message: "Bookmark ID is required." });
  }
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const { error, status } = await supabase
    .from("bookmarks")
    .delete()
    .match({ id, user_id: supabase?.auth?.user()?.id });
  if (error) {
    throw new ServerError({ status, code: error.code });
  }
}

async function getOwnBookmarkByShortId(
  short_id?: string
): Promise<BookmarkViewType> {
  if (!short_id || short_id.length === 0) {
    throw new ServerError({ status: 400, message: "Short ID is required." });
  }
  const { data, error, status } = await supabase
    .from("own_bookmarks_view")
    .select("*")
    .match({ short_id });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

async function getOwnBookmarksByLinkIds(
  link_ids: number[]
): Promise<BookmarkViewType[]> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const { data, error, status } = await supabase
    .from("own_bookmarks_view")
    .select("*")
    .in("link_id", link_ids);
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ?? [];
  }
}

async function getPublicBookmarks(page = 1): Promise<PagedBookmarksType> {
  const range = getPagination(page);
  const { data, error, status } = await supabase
    .from("expanded_bookmarked_links_view")
    .select("*")
    .order("updated_at", { ascending: false })
    .range(range.from, range.to);
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return {
      results: data ?? [],
      nextPage: data && data.length === PAGE_SIZE ? page + 1 : undefined,
    };
  }
}

async function getUserBookmarkByShortId(
  short_id?: string
): Promise<BookmarkViewType> {
  if (!short_id || short_id.length === 0) {
    throw new ServerError({ status: 400, message: "Short ID is required." });
  }
  const { data, error, status } = await supabase
    .from("user_bookmarks_view")
    .select("*")
    .match({ short_id, is_private: false });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

async function getUserBookmarks(
  id: string,
  page = 1
): Promise<PagedBookmarksType> {
  if (!id) {
    throw new ServerError({ status: 400, message: "User ID is required" });
  }
  const match: { user_id: string; is_private?: boolean } = { user_id: id };
  if (!(supabase.auth.session() && supabase?.auth?.user()?.id === id)) {
    match.is_private = false;
  }
  const range = getPagination(page);
  const { data, error, status } = await supabase
    .from("user_bookmarks_view")
    .select("*")
    .match(match)
    .order("updated_at", { ascending: false })
    .range(range.from, range.to);
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return {
      results: data ?? [],
      nextPage: data && data.length === PAGE_SIZE ? page + 1 : undefined,
    };
  }
}

//
// Bookmark Collections
//

async function getBookmarkCollections({
  id,
  user_id,
}: {
  id?: number;
  user_id?: string;
}): Promise<CollectionType[]> {
  if (!id) {
    throw new ServerError({ status: 400, message: "Bookmark ID is required." });
  }
  if (!user_id) {
    return getOwnBookmarkCollections(id);
  } else {
    return getUserBookmarkCollections(id);
  }
}

async function getOwnBookmarkCollections(
  id?: number
): Promise<CollectionType[]> {
  if (!id) {
    throw new ServerError({ status: 400, message: "Bookmark ID is required." });
  }
  const { data, error, status } = await supabase
    .from("bookmarks_collections_view")
    .select("*")
    .match({
      bookmark_id: id,
      user_id: supabase?.auth?.user()?.id,
    });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ?? [];
  }
}

async function getUserBookmarkCollections(
  id?: number
): Promise<CollectionType[]> {
  if (!id) {
    throw new ServerError({ status: 400, message: "Bookmark ID is required." });
  }
  const { data, error, status } = await supabase
    .from("bookmarks_collections_view")
    .select("*")
    .match({
      bookmark_id: id,
      is_private: false,
    });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ?? [];
  }
}

//
// Search
//

async function getSearchResults(
  q: string,
  page = 1
): Promise<PagedBookmarksType> {
  if (!q) {
    throw new ServerError({ status: 400, message: "Query is required" });
  }
  const range = getPagination(page);
  const { data, error, status } = await supabase.rpc("search_links", {
    limit_to: PAGE_SIZE,
    offset_from: range.from,
    q,
  });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return {
      results: data ?? [],
      nextPage: data && data.length === PAGE_SIZE ? page + 1 : undefined,
    };
  }
}

const actions = {
  upsertLink,
  fetchLinkMeta,
  insertBookmark,
  upsertBookmark,
  deleteBookmark,
  getOwnBookmarkByShortId,
  getOwnBookmarksByLinkIds,
  getPublicBookmarks,
  getSearchResults,
  getUserBookmarkByShortId,
  getUserBookmarks,
  getBookmarkCollections,
  getOwnBookmarkCollections,
  getUserBookmarkCollections,
};

export default actions;
