import React, { ReactElement } from "react";
import { UseQueryResult } from "react-query/types/react/types";
import { useParams } from "react-router-dom";

import { useTagBySlug, useTagBookmarks } from "tags/hooks";
import { TagType } from "tags/actions";
import BookmarkGrid, { InfiniteBookmarksResult } from "components/BookmarkGrid";
import Error404 from "components/Error/404";
import Spinner from "components/Spinner";
import { UpdateBookmarkStoreCallbackType, useApp } from "services/AppContext";
import _capitalize from "lodash/capitalize";
import { Helmet } from "react-helmet-async";

type ProfileParams = {
  tag: string;
};

function TagView(): ReactElement {
  const { updateBookmarkStore } = useApp();
  const { tag: slug } = useParams<ProfileParams>();

  const tag: UseQueryResult<TagType> = useTagBySlug(slug);
  const bookmarks = useTagBookmarks(
    tag?.data as TagType,
    updateBookmarkStore as UpdateBookmarkStoreCallbackType
  );

  if (tag.isLoading) {
    return <Spinner className="mx-auto mt-10" size="lg" />;
  }

  if (!tag.isLoading && !tag.data) {
    return <Error404 className="my-10" />;
  }

  const title = tag?.data?.name;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <div className="px-4 sm:px-6 lg:px-16">
        <h2>{_capitalize(title)}</h2>
        <BookmarkGrid bookmarks={bookmarks as InfiniteBookmarksResult} />
      </div>
    </>
  );
}

export default TagView;
