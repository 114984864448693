import clsx from "clsx";
import mailgo from "mailgo";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import NativeClickListener from "components/NativeClickListener";

import QuestionSolidIcon from "assets/icons/question-solid";

const { REACT_APP_SUPPORT_EMAIL } = process.env;

function NavDropdown(): ReactElement {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    mailgo();
  }, []);

  return (
    <NativeClickListener
      onClick={() => setIsOpen(false)}
      className="inline-block relative"
    >
      <button
        className="inline-flex items-center font-semibold text-gray-700 align-middle bg-transparent rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <QuestionSolidIcon className="w-6 h-6" />
      </button>
      <ul
        role="navigation"
        aria-label="Main"
        className={clsx("w-32 dropdown", isOpen ? "block" : "hidden")}
      >
        <li className="text-left dropdown-item">
          <Link to="/about">{t("About")}</Link>
        </li>
        <li className="text-left dropdown-item">
          <Link to="/tools">{t("Tools")}</Link>
        </li>
        {/*<li className="dropdown-item">
          <Link to="/pricing">{t('Pricing')}</Link>
        </li>*/}
        <li className="dropdown-item">
          <Link to="/privacy">{t("Privacy")}</Link>
        </li>
        <li className="dropdown-item">
          <Link to="/terms">{t("Terms")}</Link>
        </li>
        <li className="dropdown-item">
          <a href={`mailto:${REACT_APP_SUPPORT_EMAIL}`}>{t("Contact")}</a>
        </li>
      </ul>
    </NativeClickListener>
  );
}

export default NavDropdown;
