import { Auth } from "@supabase/ui";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";

import Hero from "./Hero";
import Nav from "./Nav";

function Header(): ReactElement {
  const location = useLocation();
  const { session } = Auth.useUser();

  return (
    <header role="banner" className="px-4 sm:px-6 lg:px-16">
      <div className="mx-auto max-w-7xl divide-y divide-black divide-opacity-10">
        <Nav />
        {!session && location.pathname === "/" && <Hero />}
      </div>
    </header>
  );
}

export default Header;
