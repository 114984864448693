import clsx from "clsx";
import { ReactElement, Fragment, useRef, memo } from "react";
import { UseInfiniteQueryResult } from "react-query/types/react/types";
import _isEqual from "lodash/isEqual";

import { PagedBookmarksType } from "bookmarks/actions";
import useIntersectionObserver from "utils/useIntersectionObserver";
import Preview from "./Preview";
import Spinner from "./Spinner";
import EmptyState from "./EmptyState";

export type InfiniteBookmarksResult = UseInfiniteQueryResult & {
  data: {
    pages: PagedBookmarksType[];
  };
};

type BookmarkGridProps = {
  className?: string;
  bookmarks: InfiniteBookmarksResult;
  emptyStyle?: "cake" | "sparkle";
};

function BookmarkGrid({
  className = "md:grid-cols-2 lg:grid-cols-3",
  bookmarks,
  emptyStyle = "cake",
}: BookmarkGridProps): ReactElement {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: bookmarks.fetchNextPage,
    enabled: bookmarks.hasNextPage,
  });

  if (
    bookmarks?.data?.pages?.length === 0 ||
    bookmarks?.data?.pages[0]?.results?.length === 0
  ) {
    return <EmptyState type={emptyStyle} />;
  }

  return (
    <>
      {bookmarks.data && (
        <div
          role="feed"
          aria-busy={bookmarks.isLoading || bookmarks.isFetchingNextPage}
          className={clsx(
            "grid md:grid-cols-2 lg:grid-cols-3 gap-2",
            className
          )}
        >
          {bookmarks.data?.pages &&
            bookmarks.data?.pages.map((page: PagedBookmarksType) => (
              <Fragment key={page.nextPage ?? 0}>
                {page.results.map((b) => (
                  <Preview key={b.id ?? b.link_id} bookmark={b} showActions />
                ))}
              </Fragment>
            ))}
        </div>
      )}
      <div ref={loadMoreRef} className="w-full" />
      {(bookmarks.isLoading || bookmarks.isFetchingNextPage) && (
        <Spinner className="my-10 mx-auto" size="md" />
      )}
    </>
  );
}

const MemoisedBookmarkGrid = memo(BookmarkGrid, (prevProps, nextProps) =>
  _isEqual(prevProps.bookmarks.data, nextProps.bookmarks.data)
);

export default MemoisedBookmarkGrid;
