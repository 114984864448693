import clsx from "clsx";
import mailgo from "mailgo";
import React, { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import ServerDown from "assets/svg/server_down.svg";

const { REACT_APP_SUPPORT_EMAIL } = process.env;

function Error500({ className }: { className?: string }): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    mailgo();
  }, []);

  return (
    <div
      className={clsx(
        "flex flex-col justify-center items-center px-4 w-full text-center",
        className
      )}
    >
      <img
        src={ServerDown}
        alt={t("Server Down Image")}
        className="w-full md:w-1/3"
      />
      <h1 className="mt-8">{t("Internal Server Error.")}</h1>
      <p className="mb-8 text-gray-500">
        <Trans>
          Please reload the page, or try again later.
          <br />
          If the problem persists, please{" "}
          <a href={`mailto:${REACT_APP_SUPPORT_EMAIL}`}>contact us</a> and let
          us know what you were trying to do.
        </Trans>
      </p>
      <Link to="/" className="btn btn-sm btn-solid btn-ink">
        {t("Go Home")}
      </Link>
    </div>
  );
}

export default Error500;
