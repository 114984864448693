import axios from "axios";
import deepmerge from "deepmerge";

import { ServerError } from "./RequestError";
import { PaginationRange } from "./types";
import { PAGE_SIZE, STATUS } from "../constants";

const { REACT_APP_FUNCTIONS_SERVER, REACT_APP_NETLIFY_DEV } = process.env;

const overwriteMerge = (
  destinationArray: Record<string, unknown>[],
  sourceArray: Record<string, unknown>[]
): Record<string, unknown>[] => sourceArray;

export const deepmergeOverwrite = (
  a: Record<string, unknown>,
  b: Record<string, unknown>
): Record<string, unknown> => deepmerge(a, b, { arrayMerge: overwriteMerge });

export const deepmergeAllOverwrite = (
  a: Record<string, unknown>[]
): Record<string, unknown> =>
  deepmerge.all<Record<string, unknown>>(a, { arrayMerge: overwriteMerge });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function coalesceNullsToEmptyString(o: any): any {
  return JSON.parse(
    JSON.stringify(o, (k, v) => (k !== "settings" ? v ?? "" : v ?? {}))
  );
}

export function getPagination(page = 1, size = PAGE_SIZE): PaginationRange {
  const from = page ? (page - 1) * size : 0;
  const to = page ? from + size - 1 : size - 1;

  return { from, to };
}

export function formatServerError(error: ServerError): string {
  if (error.message !== "") {
    return error.message;
  } else if (error.status) {
    return `${STATUS[error.status]}${error.code ? ` (${error.code})` : ""}`;
  } else {
    return "An error has occurred";
  }
}

export function slugify(str: string): string {
  return str
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z0-9\\-_]+/gi, "-");
}

export function monitorEvent(body: Record<string, unknown>): void {
  axios
    .post(
      `${
        REACT_APP_FUNCTIONS_SERVER
          ? `http://${REACT_APP_FUNCTIONS_SERVER}/.netlify/functions`
          : "/api"
      }/monitor/event`,
      {
        ...body,
      }
    )
    .then((response) => {
      REACT_APP_NETLIFY_DEV && console.log(response);
    })
    .catch((error) => {
      REACT_APP_NETLIFY_DEV && console.log(error);
    });
}
