import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSearch } from "bookmarks/hooks";
import Spinner from "components/Spinner";
import { useApp } from "services/AppContext";
import useDebounce from "utils/useDebounce";
import { InfiniteBookmarksResult } from "./BookmarkGrid";

import SearchIcon from "assets/icons/search";

function SearchForm(): ReactElement {
  const { updateSearchResults } = useApp();
  const { t } = useTranslation();

  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);

  const handleSearchChange = (e: { target: HTMLInputElement }) => {
    setQuery(e.target.value as string);
  };

  const searchResults = useSearch(debouncedQuery as string);

  useEffect(() => {
    if (!searchResults.error) {
      updateSearchResults(searchResults as InfiniteBookmarksResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults.error, searchResults.data]);

  return (
    <form
      role="search"
      aria-label="sitewide"
      className="group px-4 sm:px-6 lg:px-16 my-0 max-w-full bg-white shadow-sm"
    >
      <div className="flex mx-auto max-w-7xl">
        <label
          htmlFor="search-input"
          className="flex flex-none items-center pr-3 mb-0"
        >
          <span className="sr-only">{t("Search the trove")}</span>
          <SearchIcon className="text-gray-400 group-focus-within:text-gray-500 transition-colors duration-150" />
        </label>
        <input
          type="search"
          id="search-input"
          placeholder="Search the trove"
          onChange={handleSearchChange}
          className="flex-auto py-6 mb-0 text-base leading-6 placeholder-gray-400 text-gray-500 bg-transparent focus:bg-transparent border-0 focus:ring-0 shadow-none focus:outline-none"
        />
        {searchResults.isFetching && (
          <div className="self-center mt-0.5">
            <Spinner size="sm" />
          </div>
        )}
      </div>
    </form>
  );
}

export default SearchForm;
