import _compact from "lodash/compact";
import _map from "lodash/map";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from "react";

import bookmarkActions, { BookmarkViewType } from "bookmarks/actions";
import { InfiniteBookmarksResult } from "components/BookmarkGrid";
import supabase from "services/supabase";

export type UpdateBookmarkStoreCallbackType = (
  bookmarks: BookmarkViewType[]
) => void;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppContextProps {}

const AppContext = createContext<Partial<AppContextProps>>({});

function AppProvider(props: Record<string, unknown>): ReactElement {
  const [bookmarkStore, setBookmarkStore] = useState(new Map());
  const [searchResults, setSearchResults] = useState(
    {} as InfiniteBookmarksResult
  );

  const updateBookmarkStore = useCallback(
    async (incoming: BookmarkViewType[]) => {
      if (!supabase.auth.session()) {
        return;
      }
      try {
        const link_ids = _compact(_map(incoming, "link_id"));
        if (link_ids.length === 0) {
          return;
        }
        const bookmarks = await bookmarkActions.getOwnBookmarksByLinkIds(
          link_ids
        );
        if (bookmarks.length === 0) {
          return;
        }
        const tmp = new Map(bookmarks.map((o) => [o.link_id, o]));
        setBookmarkStore(
          new Map(
            (function* () {
              yield* bookmarkStore;
              yield* tmp;
            })()
          )
        );
      } catch (e) {
        console.error(e);
      }
    },
    [bookmarkStore]
  );

  const updateSearchResults = useCallback((results) => {
    setSearchResults(results ?? {});
  }, []);

  const value = {
    bookmarkStore,
    updateBookmarkStore,
    searchResults,
    updateSearchResults,
  } as AppContextType;
  return <AppContext.Provider value={value} {...props} />;
}

export type AppContextType = {
  bookmarkStore: Map<number, BookmarkViewType>;
  updateBookmarkStore: (value: BookmarkViewType[]) => void;
  searchResults: InfiniteBookmarksResult;
  updateSearchResults: (value: InfiniteBookmarksResult) => void;
};

function useApp(): AppContextType {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useApp must be used within an AppProvider.`);
  }
  return context as AppContextType;
}

export { AppProvider, useApp };
