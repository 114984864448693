import { BookmarkViewType, PagedBookmarksType } from "bookmarks/actions";
import supabase from "services/supabase";
import { ServerError } from "utils/RequestError";
import { getPagination } from "utils/helpers";
import { PAGE_SIZE } from "../constants";

export interface TagType {
  id?: number;
  slug?: string;
  name: string;
  created_at?: string;
}

//
// Individual Tag
//

async function getTagBySlug(slug: string): Promise<TagType> {
  if (!slug) {
    throw new ServerError({ status: 400, message: "Slug is required." });
  }
  const { data, error, status } = await supabase
    .from("tags")
    .select("*")
    .match({ slug });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ? data[0] : undefined;
  }
}

//
// Multiple Tags
//

async function getBookmarkTags(id: number): Promise<TagType[]> {
  if (!id) {
    throw new ServerError({ status: 400, message: "Bookmark ID is required." });
  }
  const { data, error, status } = await supabase
    .from("bookmarks_tags_view")
    .select("*")
    .match({
      bookmark_id: id,
    });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ?? [];
  }
}

async function getGlobalTags(q: string): Promise<TagType[]> {
  if (!q) {
    return [];
  }
  const { data, error, status } = await supabase.rpc("search_tags", { q });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ?? [];
  }
}

async function getPopularTags(): Promise<TagType[]> {
  const { data, error, status } = await supabase
    .from("tag_cloud_view")
    .select("*")
    .order("hits", { ascending: false });
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return data ?? [];
  }
}

async function insertTag(input: TagType): Promise<TagType> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  const created = await supabase.from("tags").insert({
    name: input.name.toLowerCase(),
  });
  if (created.error) {
    switch (created.error.code) {
      case "23505":
        const fetched = await supabase
          .from("tags")
          .select("*")
          .match({ name: input.name });
        if (fetched.error) {
          throw new ServerError({
            status: fetched.status,
            code: fetched.error.code,
          });
        } else {
          return fetched.data ? fetched.data[0] : undefined;
        }
      default:
        throw new ServerError({
          status: created.status,
          code: created.error.code,
        });
    }
  } else {
    return created.data ? created.data[0] : undefined;
  }
}

async function upsertBookmarkTags(
  bookmark: BookmarkViewType,
  tags: number[]
): Promise<boolean> {
  if (!supabase.auth.session()) {
    throw new ServerError({ status: 401 });
  }
  if (!bookmark || !tags) {
    throw new ServerError({
      status: 400,
      message: "Bookmark or tags are missing.",
    });
  }
  const current = await supabase
    .from("bookmarks_tags")
    .select("tag_id")
    .match({ bookmark_id: bookmark.id });
  if (current.error) {
    throw new ServerError({ status: current.status, code: current.error.code });
  }
  const oldTags = current.data.map((c) => c.tag_id);
  const r = oldTags.filter((x) => !tags.includes(x));
  const a = tags.filter((x) => !oldTags.includes(x));
  if (r.length > 0) {
    const removed = await supabase
      .from("bookmarks_tags")
      .delete()
      .eq("bookmark_id", bookmark.id)
      .in("tag_id", r);
    if (removed.error) {
      throw new ServerError({
        status: removed.status,
        code: removed.error.code,
      });
    }
  }
  if (a.length > 0) {
    const added = await supabase
      .from("bookmarks_tags")
      .insert(a.map((x) => ({ bookmark_id: bookmark.id, tag_id: x })));
    if (added.error) {
      throw new ServerError({ status: added.status, code: added.error.code });
    }
  }

  return true;
}

//
// Tag Bookmarks
//

async function getTagBookmarks(
  tag: TagType,
  page = 1
): Promise<PagedBookmarksType> {
  if (!tag) {
    throw new ServerError({ status: 400, message: "Tag is required." });
  }
  const range = getPagination(page);
  const { data, error, status } = await supabase
    .from("expanded_bookmarked_links_view")
    .select("*")
    .contains("tags", [tag.name])
    .order("updated_at", { ascending: false })
    .range(range.from, range.to);
  if (error) {
    throw new ServerError({ status, code: error.code });
  } else {
    return {
      results: data ?? [],
      nextPage: data && data.length === PAGE_SIZE ? page + 1 : undefined,
    };
  }
}

const actions = {
  getTagBySlug,
  getBookmarkTags,
  getGlobalTags,
  getPopularTags,
  insertTag,
  upsertBookmarkTags,
  getTagBookmarks,
};

export default actions;
