import _pick from "lodash/pick";
import React, { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { UseQueryResult } from "react-query/types/react/types";
import { useParams, Link } from "react-router-dom";

import { useUserBookmarkByShortId } from "bookmarks/hooks";
import { BookmarksSuccessCallback, BookmarkViewType } from "bookmarks/actions";
import { useUserProfile } from "auth/hooks";
import { ProfileType } from "auth/actions";
import Avatar from "components/Avatar";
import Error404 from "components/Error/404";
import Preview from "components/Preview";
import Spinner from "components/Spinner";
import { useApp } from "services/AppContext";

type BookmarkViewParams = {
  short_id: string;
};

function BookmarkView(): ReactElement {
  const { short_id } = useParams<BookmarkViewParams>();
  const { updateBookmarkStore } = useApp();

  const bookmark: UseQueryResult<BookmarkViewType> = useUserBookmarkByShortId(
    short_id,
    updateBookmarkStore as BookmarksSuccessCallback
  );
  const profile: UseQueryResult<ProfileType> = useUserProfile(
    bookmark?.data?.user_id
  );

  if (bookmark.isLoading) {
    return <Spinner fullScreen />;
  }

  if (!bookmark.isLoading && !bookmark.data) {
    return <Error404 className="my-10" />;
  }

  return (
    <>
      <Helmet>
        <title>{bookmark.data.title}</title>
        <meta name="description" content={bookmark.data.description} />
        <meta property="og:title" content={bookmark.data.title} />
        <meta property="og:description" content={bookmark.data.description} />
        {bookmark.data.image_url && (
          <meta property="og:image" content={bookmark.data.image_url} />
        )}
        <meta name="twitter:title" content={bookmark.data.title} />
        <meta name="twitter:description" content={bookmark.data.description} />
        {bookmark.data.image_url && (
          <meta name="twitter:image" content={bookmark.data.image_url} />
        )}
      </Helmet>
      <div className="my-10 mx-auto w-full max-w-lg">
        <Preview
          bookmark={
            _pick(bookmark.data, [
              "link_id",
              "url",
              "title",
              "description",
              "image_url",
              "tags",
            ]) as BookmarkViewType
          }
          showActions
        />
        {!profile.isLoading && profile.data && (
          <div className="p-3 card">
            <div className="flex items-center pb-2 mb-2 space-x-3 font-bold border-b">
              <Avatar profile={profile.data as ProfileType} />
              <Link
                to={`/@${profile.data.username}`}
                className="no-underline"
              >{`@${profile.data.username}`}</Link>
            </div>
            <div className="text-sm whitespace-pre-line">
              {bookmark && <div>{bookmark.data?.comment}</div>}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BookmarkView;
