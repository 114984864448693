import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import _pick from "lodash/pick";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query/types/react/types";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import { CollectionType } from "collections/actions";
import {
  useOwnCollectionBySlug,
  useCollectionDelete,
  useCollectionUpsert,
} from "collections/hooks";
import Error404 from "components/Error/404";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import Tooltip from "components/Tooltip";
import { ServerError } from "utils/RequestError";

import ChevronRightIcon from "assets/icons/chevron-right";
import LibraryIcon from "assets/icons/library";
import QuestionSolidIcon from "assets/icons/question-solid";
import { formatServerError } from "utils/helpers";

type CollectionFormParams = {
  slug?: string;
};

type FormValues = {
  id?: string;
  name: string;
  is_private: boolean;
};

const CollectionSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  name: Yup.string().nullable().max(500),
  is_private: Yup.bool().label("privacy setting"),
});

const initialState = {
  id: "",
  name: "",
  is_private: false,
};

function CollectionForm(): ReactElement {
  const history = useHistory();
  const { slug } = useParams<CollectionFormParams>();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: initialState,
    resolver: yupResolver(CollectionSchema),
  });

  const collection: UseQueryResult<CollectionType> = useOwnCollectionBySlug(
    slug as string
  );
  const deleteCollection = useCollectionDelete();
  const updateCollection = useCollectionUpsert();

  const handleDelete = async () => {
    if (!collection?.data?.id) {
      toast.error(t("Collection not found."));
      return;
    }
    deleteCollection.mutate(collection.data.id, {
      onSuccess: async () => {
        history.push("/dashboard");
        toast.success(t("Deleted"));
      },
      onError: (e) => {
        toast.error(t(formatServerError(e as ServerError)));
      },
    });
  };

  const onSubmit = async (input: CollectionType) => {
    updateCollection.mutate(input, {
      onSuccess: async (data) => {
        if (slug !== (data as CollectionType).slug) {
          history.push(`/collections/${(data as CollectionType).slug}/edit`);
        }
        toast.success(t("Saved"));
      },
      onError: (e) => {
        toast.error(t(formatServerError(e as ServerError)));
      },
    });
  };

  useEffect(() => {
    if (slug && !collection.isLoading && collection.data) {
      reset({
        ...initialState,
        ..._pick(collection?.data, Object.keys(initialState)),
        id: String(collection.data.id ?? ""),
      });
    } else {
      reset({ ...initialState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, collection.isLoading, collection.data]);

  if (slug && collection.isLoading) {
    return <Spinner fullScreen />;
  }

  if (slug && !collection.isLoading && !collection.data) {
    return <Error404 className="my-10" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <nav aria-label="breadcrumb" className="text-sm">
        <ul className="inline-flex items-center space-x-2">
          <li className="inline-flex items-center">
            <LibraryIcon className="mr-2 w-4 h-4" />
            <Link to="/dashboard">My Trove</Link>
          </li>
          <li aria-current="page" className="inline-flex items-center">
            <ChevronRightIcon className="mr-2 w-4 h-4" />
            {slug ? t("Edit Collection") : t("Add Collection")}
          </li>
        </ul>
      </nav>
      <h2 className="group flex items-center mb-8">
        {slug ? t("Edit Collection") : t("Add Collection")}
        {slug && (
          <Link
            to={`/collections/${slug}`}
            className="hidden group-hover:inline-block ml-2 btn btn-xs btn-outline"
          >
            {t("View")}
          </Link>
        )}
      </h2>
      <div className="form-row">
        <div className="w-full form-group">
          <label htmlFor="name">{t("name")}</label>
          <input
            id="name"
            type="text"
            maxLength={256}
            {...register("name")}
            className={clsx(errors.name && "border-red-500")}
          />
          {errors.name && (
            <p className="form-hint error">{errors.name.message}</p>
          )}
        </div>
      </div>
      <div className="form-row">
        <div className="w-full form-group">
          <input id="is_private" type="checkbox" {...register("is_private")} />
          <label htmlFor="is_private" className="inline-block ml-2">
            <span>{t("Private")}</span>
            <Tooltip text="Not visible to the public.">
              <QuestionSolidIcon className="inline-block w-3 h-3 align-baseline" />
            </Tooltip>
          </label>
        </div>
      </div>
      <input id="id" type="hidden" {...register("id")} />
      <div className="mt-2 space-x-2">
        <button
          type="submit"
          className="btn btn-sm btn-solid btn-ink"
          disabled={!isDirty || isSubmitting || updateCollection.isLoading}
        >
          <span>{t("Save")}</span>
          <span
            className={clsx(
              "ml-2 w-4 h-4 border-2 loader inverse",
              !isSubmitting && !updateCollection.isLoading && "hidden"
            )}
          />
        </button>
        {slug && (
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="btn btn-sm btn-solid btn-danger"
            disabled={deleteCollection.isLoading}
          >
            <span>{t("Delete")}</span>
            <span
              className={clsx(
                "ml-2 w-4 h-4 border-2 loader inverse",
                !deleteCollection.isLoading && "hidden"
              )}
            />
          </button>
        )}
        <button
          type="button"
          onClick={() => history.push("/dashboard")}
          className="btn btn-sm"
        >
          <span>{t("Back")}</span>
        </button>
      </div>
      {showModal && (
        <Modal
          title={t("Delete collection")}
          onCancel={() => setShowModal(false)}
          onConfirm={() => handleDelete()}
          confirmText={t("Delete")}
          confirmClassName="bg-red-500 text-white"
          modalClassName="w-full h-full md:w-96 md:h-auto"
        >
          <p>{t("Are you sure?")}</p>
        </Modal>
      )}
    </form>
  );
}

export default CollectionForm;
