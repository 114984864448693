// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Helmet } from "react-helmet-async";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";

const { REACT_APP_SITE_URL } = process.env;

type MetaType = {
  name: string;
  content: string;
};

type SeoProps = {
  title?: string;
  description?: string;
  image?: string;
  lang?: string;
  meta?: MetaType[];
};

function Seo({
  title,
  description,
  image,
  lang = "en-AU",
}: SeoProps): ReactElement {
  const location = useLocation();

  const defaultTitle = "Thesaurus Inventus";
  const metaDescription =
    description || "A treasure trove for research and resources for writers.";
  const image_url = image ?? `${REACT_APP_SITE_URL}/card.png`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${defaultTitle}`}
      defaultTitle={defaultTitle}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:site_name",
          content: defaultTitle,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: `${REACT_APP_SITE_URL}${location.pathname}`,
        },
        {
          property: "og:image",
          content: image_url,
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:site",
          content: `@thesaurinventus`,
        },
        {
          name: "twitter:url",
          content: `${REACT_APP_SITE_URL}${location.pathname}`,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          name: "twitter:image",
          content: image_url,
        },
      ]}
    />
  );
}

export default Seo;
