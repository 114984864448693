import { Auth } from "@supabase/ui";
import React, { ReactElement, useEffect } from "react";
import Countdown from "react-countdown";
import { useLocation } from "react-router-dom";
import { Trans as BaseTrans } from "react-i18next";

type TransProps = {
  i18nKey: string;
  seconds: number;
};

const COUNTDOWN = 3000;

const Trans = BaseTrans as React.FC<TransProps & React.HTMLProps<Element>>;

function ExtensionHelper(): ReactElement {
  const { session } = Auth.useUser();
  const { pathname } = useLocation();

  useEffect(() => {
    if (session && pathname === "/bookmarks/add") {
      setTimeout(() => {
        window.close();
      }, COUNTDOWN);
    }
  }, [session, pathname]);

  if (session && pathname === "/bookmarks/add") {
    return (
      <Countdown
        date={Date.now() + COUNTDOWN}
        renderer={({ seconds, completed }) =>
          !completed && (
            <div className="my-6 mx-4 text-center">
              <Trans i18nKey="extensionSavedCountdown" seconds={seconds}>
                Saved. Closing in {{ seconds }}...
              </Trans>
            </div>
          )
        }
      />
    );
  }

  return <></>;
}

export default ExtensionHelper;
