import { Auth } from "@supabase/ui";
import clsx from "clsx";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query/types/react/types";
import { Link, useHistory } from "react-router-dom";

import { useOwnProfile } from "auth/hooks";
import { ProfileType } from "auth/actions";
import Avatar from "components/Avatar";
import NativeClickListener from "components/NativeClickListener";
import Spinner from "components/Spinner";
import supabase from "services/supabase";

import LogoutIcon from "assets/icons/logout";
import CogIcon from "assets/icons/cog";
import UserCircleIcon from "assets/icons/user-circle";
import LibraryIcon from "assets/icons/library";
import ChevronDownIcon from "assets/icons/chevron-down";

function UserDropdown(): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();
  const { session } = Auth.useUser();
  const profile: UseQueryResult<ProfileType | null> = useOwnProfile();

  const [isOpen, setIsOpen] = useState(false);

  const signOut = async () => {
    await supabase.auth.signOut();
    history.push("/");
  };

  if (session && profile.isLoading) {
    return <Spinner size="sm" />;
  }

  return (
    <NativeClickListener
      onClick={() => setIsOpen(false)}
      className="inline-block relative"
    >
      <button
        className="inline-flex items-center p-1 space-x-1 font-semibold text-gray-700 bg-transparent rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Avatar profile={profile.data} />
        <ChevronDownIcon className="w-3 h-3" />
      </button>
      <ul
        role="navigation"
        aria-label="user"
        className={clsx("dropdown", isOpen ? "block" : "hidden")}
      >
        <li className="text-left dropdown-item">
          <Link to="/dashboard" className="space-x-2">
            <LibraryIcon className="inline w-4 h-4" />
            <span>{t("My Trove")}</span>
          </Link>
        </li>
        {profile?.data?.username && (
          <li className="text-left dropdown-item">
            <Link to={`/@${profile.data.username}`} className="space-x-2">
              <UserCircleIcon className="inline w-4 h-4" />
              <span>{t("My Page")}</span>
            </Link>
          </li>
        )}
        <li className="text-left dropdown-item">
          <Link to="/account" className="space-x-2">
            <CogIcon className="inline w-4 h-4" />
            <span>{t("Account")}</span>{" "}
            {profile?.data && !profile?.data.username && (
              <span className="group inline-block relative px-1.5 text-xs text-white bg-red-500 rounded-full">
                !
                <span className="hidden group-hover:block absolute -top-9 right-1 p-2 bg-black rounded-md opacity-70">
                  {t("please complete your profile")}
                </span>
              </span>
            )}
          </Link>
        </li>
        <li className="dropdown-item">
          <button onClick={signOut} className="space-x-2 w-full text-left">
            <LogoutIcon className="inline w-4 h-4" />
            <span>{t("Logout")}</span>
          </button>
        </li>
      </ul>
    </NativeClickListener>
  );
}

export default UserDropdown;
