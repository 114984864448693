import { Auth } from "@supabase/ui";
import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";

import Spinner from "components/Spinner";
import { DefaultLayout } from "./DefaultRoute";

type PrivateRouteProps = RouteProps & {
  component: FunctionComponent;
  showFab?: boolean;
  isExt?: boolean;
} & Record<string, unknown>;

function PrivateRoute({
  component,
  showFab,
  isExt,
  ...rest
}: PrivateRouteProps): ReactElement {
  const { session } = Auth.useUser();
  const location = useLocation();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isReady ? (
          <Spinner fullScreen />
        ) : session ? (
          <DefaultLayout
            component={component}
            showFab={showFab}
            isExt={isExt}
            {...props}
          />
        ) : (
          <Redirect
            exact
            to={{
              ...location,
              pathname: "/login",
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
