import _isEqual from "lodash/isEqual";
import React, { memo, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";

import { CollectionType } from "collections/actions";
import Tooltip from "components/Tooltip";

import InvisibleIcon from "assets/icons/invisible";

interface CollectionListProps {
  collections?: CollectionType[] | null;
}

type CollectionListParams = {
  username?: string;
};

function CollectionList({
  collections = [],
}: CollectionListProps): ReactElement {
  const history = useHistory();
  const { username } = useParams<CollectionListParams>();
  const { t } = useTranslation();

  const handleClick = () => {
    history.push("/collections/add");
  };

  return (
    <div className="text-left">
      <h3 className="group mt-0">
        {t("Collections")}{" "}
        <button
          className="hidden group-hover:inline-block ml-2 font-sans align-text-bottom btn btn-xs btn-outline"
          onClick={handleClick}
        >
          {t("Add")}
        </button>
      </h3>
      {collections && collections.length > 0 ? (
        <ul>
          {collections.map((c) => (
            <li key={c.id}>
              <Link
                to={`/${username ? `@${username}` : "collections"}/${c.slug}`}
              >
                {c.name}
              </Link>
              {c.is_private && (
                <Tooltip text="Private">
                  <InvisibleIcon className="inline-block ml-1 w-3 h-3" />
                </Tooltip>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-sm">{t("No collections")}</p>
      )}
    </div>
  );
}

const MemoisedCollectionList = memo(CollectionList, (prevProps, nextProps) =>
  _isEqual(prevProps.collections, nextProps.collections)
);

export default MemoisedCollectionList;
