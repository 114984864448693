type StatusType = {
  [propName: string]: string;
};

export const STATUS: StatusType = {
  "400": "Bad request",
  "401": "Unauthorized",
  "403": "Forbidden",
  "404": "Not found",
  "405": "Method not allowed",
  "409": "Conflict",
  "410": "Gone",
  "413": "Payload too large",
  "500": "Internal server error",
  "503": "Service unavailable",
};

export const PAGE_SIZE = 50;
